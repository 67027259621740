import {useEffect} from 'react';
import {gql, useMutation} from '@apollo/client';
import {notification} from 'antd';
import {
  adminSetPasswordWithToken,
  adminSetPasswordWithTokenVariables,
} from './__generated__/adminSetPasswordWithToken';
import {useHistory} from 'react-router';
import {useDispatch} from 'react-redux';
import Auth from '../../../logic/redux/auth';
import {RouteNames} from '../../../routes/path';

const useSetPassword = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [
    setPasswordCall,
    {
      data: setPasswordData,
      loading: setPasswordLoading,
      error: setPasswordError,
    },
  ] = useMutation<
    adminSetPasswordWithToken,
    adminSetPasswordWithTokenVariables
  >(gql`
    mutation adminSetPasswordWithToken(
      $updatePasswordToken: String!
      $password: String!
    ) {
      adminSetPasswordWithToken(
        updatePasswordToken: $updatePasswordToken
        password: $password
      )
    }
  `);

  useEffect(() => {
    if (setPasswordError) {
      switch (setPasswordError.message) {
        /* TODO UPDATE */
        /* case 'wrong_email_or_password':
          notification['error']({
            message: 'Wrong email or password!',
          });
          break; */
        default:
          notification['error']({
            message: 'Error set new password!',
          });
      }
    }
  }, [setPasswordError]);

  useEffect(() => {
    if (setPasswordData) {
      dispatch(Auth.actions.setUserLoggedIn(false));
      history.push(RouteNames.LOGIN);
      notification['success']({
        message: 'Your password has changed',
      });
    }
  }, [setPasswordData]);

  return {setPasswordCall, setPasswordLoading, setPasswordError};
};

export default useSetPassword;

import produce from 'immer';
import App from '.';
import {IAppReducer} from './app-models';

const initializeState: IAppReducer = {
  resetMenuArray: false,
};

export default (
  state = initializeState,
  action: {type: string; payload: App.model.AppActionsPayload},
): IAppReducer =>
  produce(state, (draft) => {
    switch (action.type) {
      case App.actionTypes.RESET_MENU_ARRAY:
        draft.resetMenuArray = action.payload as boolean;
        return draft;
      default:
        return state;
    }
  });

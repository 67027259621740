import {InfoCircleOutlined} from '@ant-design/icons';
import styled from 'styled-components';
import {ColorLinkBlue} from '../../styled';

export const RowTitle = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin: 0;
`;

export const IconInfoLink = styled(InfoCircleOutlined)`
  font-size: 18px;
  margin: 0 8px 3px 5px;
  color: ${ColorLinkBlue};
`;

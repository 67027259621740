import React from 'react';
import ReactDOM from 'react-dom';
import {Provider as ReduxProvider} from 'react-redux';
import {ConnectedRouter} from 'connected-react-router';
import {store, history} from './logic/redux/store';
import './index.css';
import Routes from './routes/routes';
import reportWebVitals from './reportWebVitals';
import 'antd/dist/antd.css';
//import { ApolloProvider } from "@apollo/client";
//import client from "./generated/graphql-apollo";
import GqlProvider from './io/api/graphql/gql_provider';

ReactDOM.render(
  <React.StrictMode>
    <ReduxProvider store={store}>
      <ConnectedRouter history={history}>
        <GqlProvider>
          <div className="app-container">
            <Routes />
          </div>
        </GqlProvider>
      </ConnectedRouter>
    </ReduxProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import {useState, useEffect} from 'react';
import {gql, useLazyQuery, useMutation} from '@apollo/client';
import {
  adminListPaymentStatistics,
  adminListPaymentStatisticsVariables,
} from './__generated__/adminListPaymentStatistics';
import {
  adminListPayouts,
  adminListPayoutsVariables,
} from './__generated__/adminListPayouts';
import {adminCompletePayoutRequestVariables} from './__generated__/adminCompletePayoutRequest';
import {notification} from 'antd';

const usePaymentStatisticsScreen = () => {
  const [page, setPage] = useState<number>(1);
  const [csvPage, setCsvPage] = useState<number>(1);
  const [search, setSearch] = useState<string>('');
  const pageSize = 50;
  const csvPageSize = 10;

  const [
    getPaymentStatistics,
    {data: paymentStatisticsData, loading: paymentStatisticsLoading},
  ] = useLazyQuery<
    adminListPaymentStatistics,
    adminListPaymentStatisticsVariables
  >(
    gql`
      query adminListPaymentStatistics(
        $paginator: Paginator!
        $filter: PaymentStatisticFilter!
      ) {
        adminListPaymentStatistics(paginator: $paginator, filter: $filter) {
          pageCount
          paymentStatistics {
            id
            uploader {
              name
            }
            date
            commission
            payoutCsvName
          }
        }
      }
    `,
    {
      variables: {
        paginator: {
          page: page,
          perPage: pageSize,
        },
        filter: {
          keyword: '',
        },
      },
      fetchPolicy: 'network-only',
    },
  );

  const [
    getAdminListPayouts,
    {data: adminListPayoutsData, loading: adminListPayoutsLoading},
  ] = useLazyQuery<adminListPayouts, adminListPayoutsVariables>(
    gql`
      query adminListPayouts($paginator: Paginator!) {
        adminListPayouts(paginator: $paginator) {
          payouts {
            id
            name
            done
          }
          pageCount
        }
      }
    `,
    {
      variables: {
        paginator: {
          page: csvPage,
          perPage: csvPageSize,
        },
      },
      fetchPolicy: 'network-only',
    },
  );

  const [
    adminPayoutCsv,
    {data: adminPayoutCsvData, loading: adminPayoutCsvLoading},
  ] = useMutation(gql`
    mutation adminPayoutCsv {
      adminPayoutCsv
    }
  `);

  const [
    adminCompletePayout,
    {data: adminCompletePayoutData, loading: adminCompletePayoutLoading},
  ] = useMutation<adminCompletePayoutRequestVariables>(gql`
    mutation adminCompletePayoutRequest($payoutId: Int!) {
      adminCompletePayoutRequest(payoutId: $payoutId)
    }
  `);

  /* Query new data on load and page change */
  useEffect(() => {
    getPaymentStatistics({
      variables: {
        paginator: {
          page: page,
          perPage: pageSize,
        },
        filter: {
          keyword: '',
        },
      },
    });
  }, [page]);

  useEffect(() => {
    getAdminListPayouts({
      variables: {
        paginator: {
          page: csvPage,
          perPage: csvPageSize,
        },
      },
    });
  }, [csvPage]);

  const onSearch = (e: string) => {
    setSearch(e);
    getPaymentStatistics({
      variables: {
        paginator: {
          page: page,
          perPage: pageSize,
        },
        filter: {
          keyword: e,
        },
      },
    });
  };

  const onGenerateCsv = () => {
    adminPayoutCsv().then((res) => {
      if (res.errors === null || res.errors === undefined) {
        notification['success']({
          message: 'Success',
          description: 'csv generation is successful',
        });
        getPaymentStatistics({
          variables: {
            paginator: {
              page: page,
              perPage: pageSize,
            },
            filter: {
              keyword: search,
            },
          },
        });
        getAdminListPayouts({
          variables: {
            paginator: {
              page: csvPage,
              perPage: csvPageSize,
            },
          },
        });
      }
    });
  };

  const onCompleteCsv = (id: number) => {
    adminCompletePayout({variables: {payoutId: id}}).then((res) => {
      if (res.errors === null || res.errors === undefined) {
        notification['success']({
          message: 'Success',
          description: 'complete is successful',
        });
        getPaymentStatistics({
          variables: {
            paginator: {
              page: page,
              perPage: pageSize,
            },
            filter: {
              keyword: search,
            },
          },
        });
        getAdminListPayouts({
          variables: {
            paginator: {
              page: csvPage,
              perPage: csvPageSize,
            },
          },
        });
      }
    });
  };

  return {
    paymentStatisticsData,
    paymentStatisticsLoading,
    setPage,
    page,
    pageSize,
    onSearch,
    onGenerateCsv,
    adminPayoutCsvLoading,
    adminListPayoutsData,
    adminListPayoutsLoading,
    csvPage,
    setCsvPage,
    csvPageSize,
    onCompleteCsv,
  };
};

export default usePaymentStatisticsScreen;

import {useState, useEffect} from 'react';
import {gql, useLazyQuery, useMutation} from '@apollo/client';
import {
  adminListPlaces,
  adminListPlacesVariables,
} from './__generated__/adminListPlaces';
import {
  adminDeletePlace,
  adminDeletePlaceVariables,
} from './__generated__/adminDeletePlace';
import {Modal} from 'antd';

const usePlacesScreen = () => {
  const [page, setPage] = useState<number>(1);
  const pageSize = 50;

  const [getPlaces, {data: placesData, loading: placesLoading}] = useLazyQuery<
    adminListPlaces,
    adminListPlacesVariables
  >(
    gql`
      query adminListPlaces($paginator: Paginator!, $filter: PlaceFilter!) {
        adminListPlaces(paginator: $paginator, filter: $filter) {
          pageCount
          places {
            id
            name
            country
            approved
            active
          }
        }
      }
    `,
    {
      variables: {
        paginator: {
          page: page,
          perPage: pageSize,
        },
        filter: {
          keyword: '',
          approved: undefined,
        },
      },
      fetchPolicy: 'network-only',
    },
  );

  const [deletePlace, {error: deletePlaceError, loading: deletePlaceLoading}] =
    useMutation<adminDeletePlace, adminDeletePlaceVariables>(gql`
      mutation adminDeletePlace($placeId: Int!) {
        adminDeletePlace(placeId: $placeId)
      }
    `);

  /* Query new data on load and page change */
  useEffect(() => {
    getPlaces({
      variables: {
        paginator: {
          page: page,
          perPage: pageSize,
        },
        filter: {
          keyword: '',
          approved: undefined,
        },
      },
    });
  }, [page]);

  const onSearch = (e: string) => {
    getPlaces({
      variables: {
        paginator: {
          page: page,
          perPage: pageSize,
        },
        filter: {
          keyword: e,
        },
      },
    });
  };

  const onDeletePlace = (placeIdCurrent: number) => {
    deletePlace({
      variables: {
        placeId: placeIdCurrent,
      },
    }).then(() => {
      getPlaces({
        variables: {
          paginator: {
            page: page,
            perPage: pageSize,
          },
          filter: {
            keyword: '',
            approved: undefined,
          },
        },
      });
    });
  };

  useEffect(() => {
    if (deletePlaceError) {
      Modal.error({
        title: 'Error delete place',
        content: 'Something went wrong, place deletion failed!',
        onOk() {
          () => Modal.destroyAll();
        },
      });
    }
  }, [deletePlaceError]);

  return {
    placesData,
    placesLoading,
    setPage,
    page,
    pageSize,
    onSearch,
    onDeletePlace,
    deletePlaceLoading,
  };
};

export default usePlacesScreen;

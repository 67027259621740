import * as React from 'react';
import {useHistory} from 'react-router-dom';
import {
  ContainerTableContent,
  ContainerSearchSection,
  RowSearchSection,
  TextSectionTitle,
  ColumnSearchSection,
  ContainerTable,
  SearchCustom,
  TableContent,
  RowInformationSection,
  TextInformation,
  IconInfo,
  ContainerPagiantion,
} from '../../styled';
import {Button, Modal, Pagination, Tag} from 'antd';
import {
  CheckOutlined,
  CloseOutlined,
  DeleteOutlined,
  ExclamationCircleOutlined,
} from '@ant-design/icons';
import useToursScreen from './hooks/use-tours-screen';
import Loading from '../../components/loading/loading';

const {confirm} = Modal;
interface Tours {
  id: number;
  name: string;
  country: string;
  approved: boolean;
  active: boolean;
  rate: number;
}

const ToursScreen = (): JSX.Element => {
  const history = useHistory();

  const {
    toursData,
    toursLoading,
    setPage,
    page,
    pageSize,
    onSearch,
    onDeleteTour,
    deleteTourLoading,
  } = useToursScreen();
  const tours = toursData?.adminListTours.tours || [];
  const pageCount = toursData?.adminListTours.pageCount;
  const toursTableDataSource: Tours[] = tours.map((tour) => ({
    id: tour.id,
    name: tour.name,
    country: tour.country,
    approved: tour.approved,
    active: tour.active,
    rate: tour.rate,
  }));

  const columns = [
    {
      title: 'Tour name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Tour id',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'Country',
      dataIndex: 'country',
      key: 'country',
    },
    {
      title: 'Rate',
      dataIndex: 'rate',
      key: 'rate',
      render: (data: number) => {
        return <span>{data.toFixed(1)}</span>;
      },
    },
    {
      title: 'Status',
      dataIndex: 'approved',
      key: 'approved',
      render: (data: string) => {
        return data ? (
          <Tag color={'cyan'}>
            <CheckOutlined />
          </Tag>
        ) : (
          <Tag color={'orange'}>
            <CloseOutlined />
          </Tag>
        );
      },
    },
    {
      title: 'Active',
      dataIndex: 'active',
      key: 'active',
      render: (data: string) => {
        return data ? (
          <Tag color={'green'}>
            <CheckOutlined />
          </Tag>
        ) : (
          <Tag color={'red'}>
            <CloseOutlined />
          </Tag>
        );
      },
    },
    {
      dataIndex: 'id',
      key: 'details',
      render: (record: number) => {
        return (
          <Button
            type="primary"
            onClick={() => history.push(`/tours/${record}`)}>
            Details
          </Button>
        );
      },
    },
    {
      dataIndex: 'id',
      key: 'delete',
      render: (record: number) => {
        return (
          <Button
            danger
            icon={<DeleteOutlined />}
            onClick={() =>
              confirm({
                title: 'Are you sure to delete this tour?',
                icon: <ExclamationCircleOutlined />,
                content: 'This operation cannot be revoked!',
                onOk() {
                  onDeleteTour(record);
                },
                onCancel() {
                  Modal.destroyAll();
                },
                okText: 'Yes',
                cancelText: 'Cancel',
              })
            }
          />
        );
      },
    },
  ];

  if (deleteTourLoading) {
    return <Loading />;
  }

  return (
    <ContainerTableContent>
      {/* Search bar section */}
      <ContainerSearchSection>
        <RowSearchSection>
          <ColumnSearchSection>
            <TextSectionTitle>Tours</TextSectionTitle>
            <SearchCustom
              placeholder="Search name, id, country"
              allowClear
              enterButton="Search"
              size="middle"
              onSearch={(e) => onSearch(e)}
            />
          </ColumnSearchSection>
        </RowSearchSection>
      </ContainerSearchSection>

      <ContainerTable>
        {/* Information */}
        <RowInformationSection>
          <IconInfo />
          <TextInformation>
            The list below contains the tours you have created and the
            information related to them. You can create a new tour from the
            Locations menu, by clicking the Create Tour button in the row for
            that Location. If you want to make changes to an existing Tour, you
            can do so by clicking on the Details button in this list. If you
            make changes to a Tour, the Tour will be inactivated, i.e. it will
            not be available in the app until an admin approves the changes.
          </TextInformation>
        </RowInformationSection>

        {/* Table */}
        <TableContent
          dataSource={toursTableDataSource}
          columns={columns}
          pagination={false}
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          rowKey={(record) => record.id}
          loading={toursLoading}
        />
        <ContainerPagiantion>
          <Pagination
            onChange={setPage}
            pageSize={pageSize}
            total={
              pageCount !== undefined ? pageCount * pageSize : 1 * pageSize
            }
            current={page}
            showSizeChanger={false}
          />
        </ContainerPagiantion>
      </ContainerTable>
    </ContainerTableContent>
  );
};

export default ToursScreen;

import * as React from 'react';
import {
  ContainerTableContent,
  ContainerSearchSection,
  RowSearchSection,
  TextSectionTitle,
  ColumnSearchSection,
  ContainerTable,
  SearchCustom,
  TableContent,
  RowInformationSection,
  TextInformation,
  IconInfo,
  ContainerPagiantion,
  ContainerDivider,
} from '../../styled';
import {Pagination, Button} from 'antd';
import usePaymentStatisticsScreen from './hooks/use-payment-statistics-screen';
import config from '../../io/api/config/api-config';
interface PaymentStatistics {
  id: number;
  uploader: string;
  date: string;
  commission: number;
  payoutCsvName: string | null;
}

interface Payout {
  id: number;
  done: boolean;
  name: string;
}

const PaymentStatisticsScreen = (): JSX.Element => {
  const {
    paymentStatisticsData,
    paymentStatisticsLoading,
    setPage,
    page,
    pageSize,
    onSearch,
    onGenerateCsv,
    adminPayoutCsvLoading,
    adminListPayoutsData,
    adminListPayoutsLoading,
    csvPage,
    setCsvPage,
    csvPageSize,
    onCompleteCsv,
  } = usePaymentStatisticsScreen();
  const payoutCsvTableDataSource =
    adminListPayoutsData?.adminListPayouts.payouts || [];
  const csvPageCount = adminListPayoutsData?.adminListPayouts.pageCount;
  const paymentStatistics =
    paymentStatisticsData?.adminListPaymentStatistics.paymentStatistics || [];
  const pageCount = paymentStatisticsData?.adminListPaymentStatistics.pageCount;
  const paymentStatisticsTableDataSource: PaymentStatistics[] =
    paymentStatistics.map((paymentStatistic) => ({
      id: paymentStatistic.id,
      uploader: paymentStatistic.uploader.name,
      date: paymentStatistic.date,
      commission: paymentStatistic.commission,
      payoutCsvName: paymentStatistic.payoutCsvName,
    }));

  const columns = [
    {
      title: 'Uploader',
      dataIndex: 'uploader',
      key: 'uploader',
    },
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date',
    },
    {
      title: 'Commission',
      dataIndex: 'commission',
      key: 'commission',
    },
    {
      title: 'CSV Name',
      dataIndex: 'payoutCsvName',
      key: 'payoutCsvName',
    },
  ];

  const csvColumns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      render: (st: string) => (
        <Button
          type="link"
          onClick={() => {
            const newWindow = window.open(
              config.backend_url + '/payout/' + st,
              'noopener,noreferrer',
            );
            if (newWindow) newWindow.opener = null;
          }}>
          {st}
        </Button>
      ),
    },
    {
      title: 'Action',
      key: 'done',
      render: (record: Payout) =>
        record.done ? (
          <>Completed</>
        ) : (
          <Button
            type="primary"
            onClick={() => {
              console.log(record);
              onCompleteCsv(record.id);
            }}>
            Complete
          </Button>
        ),
    },
  ];

  return (
    <ContainerTableContent>
      <ContainerSearchSection>
        <RowSearchSection>
          <TextSectionTitle>Payout csv</TextSectionTitle>
          <Button
            type="primary"
            loading={adminPayoutCsvLoading}
            onClick={() => onGenerateCsv()}>
            Generate CSV
          </Button>
        </RowSearchSection>
      </ContainerSearchSection>
      {/* Table */}
      <ContainerTable>
        <TableContent
          dataSource={payoutCsvTableDataSource}
          columns={csvColumns}
          pagination={false}
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          rowKey={(record) => record.id}
          loading={adminListPayoutsLoading}
        />
        <ContainerPagiantion>
          <Pagination
            onChange={setCsvPage}
            pageSize={csvPageSize}
            total={
              csvPageCount !== undefined
                ? csvPageCount * csvPageSize
                : 1 * csvPageSize
            }
            current={csvPage}
            showSizeChanger={false}
          />
        </ContainerPagiantion>
      </ContainerTable>
      <ContainerDivider />
      {/* Search bar section */}
      <ContainerSearchSection>
        <RowSearchSection>
          <ColumnSearchSection>
            <TextSectionTitle>Payment statistics</TextSectionTitle>
            <SearchCustom
              placeholder="Search uploader"
              allowClear
              enterButton="Search"
              size="middle"
              onSearch={(e) => onSearch(e)}
            />
          </ColumnSearchSection>
        </RowSearchSection>
      </ContainerSearchSection>
      <ContainerTable>
        {/* Information */}
        <RowInformationSection>
          <IconInfo />
          <TextInformation>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
            ad minim veniam.
          </TextInformation>
        </RowInformationSection>

        {/* Table */}
        <TableContent
          dataSource={paymentStatisticsTableDataSource}
          columns={columns}
          pagination={false}
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          rowKey={(record) => record.id}
          loading={paymentStatisticsLoading}
        />
        <ContainerPagiantion>
          <Pagination
            onChange={setPage}
            pageSize={pageSize}
            total={
              pageCount !== undefined ? pageCount * pageSize : 1 * pageSize
            }
            current={page}
            showSizeChanger={false}
          />
        </ContainerPagiantion>
      </ContainerTable>
    </ContainerTableContent>
  );
};

export default PaymentStatisticsScreen;

import * as React from 'react';
import {
  ContainerTableContent,
  ContainerSearchSection,
  RowSearchSection,
  TextSectionTitle,
  ColumnSearchSection,
  ContainerTable,
  SearchCustom,
  TableContent,
  RowInformationSection,
  TextInformation,
  IconInfo,
  ContainerPagiantion,
} from '../../styled';
import {Pagination} from 'antd';

import useTourStatistics from './hooks/use-tour-statistics-screen';
interface TourStatistics {
  tourName: string;
  uploader: string;
  placeName: string;
  downloadCount: number;
  totalIncome: number;
}

const TourStatisticsScreen = (): JSX.Element => {
  const {
    tourStatisticsData,
    tourStatisticsLoading,
    setPage,
    page,
    pageSize,
    onSearch,
  } = useTourStatistics();
  const tourStatistics =
    tourStatisticsData?.adminListTourStatistics.tourStatistics || [];
  const pageCount = tourStatisticsData?.adminListTourStatistics.pageCount;
  const tourStatisticsTableDataSource: TourStatistics[] = tourStatistics.map(
    (tourStatistic) => ({
      tourName: tourStatistic.tourName,
      uploader: tourStatistic.uploader.name,
      placeName: tourStatistic.placeName,
      downloadCount: tourStatistic.downloadCount,
      totalIncome: tourStatistic.totalIncome,
    }),
  );

  const columns = [
    {
      title: 'Tour name',
      dataIndex: 'tourName',
      key: 'tourName',
    },
    {
      title: 'Uploader',
      dataIndex: 'uploader',
      key: 'uploader',
    },
    {
      title: 'Place',
      dataIndex: 'placeName',
      key: 'placeName',
    },
    {
      title: 'Download count',
      dataIndex: 'downloadCount',
      key: 'downloadCount',
    },
    {
      title: 'Total income',
      dataIndex: 'totalIncome',
      key: 'totalIncome',
    },
  ];

  return (
    <ContainerTableContent>
      {/* Search bar section */}
      <ContainerSearchSection>
        <RowSearchSection>
          <ColumnSearchSection>
            <TextSectionTitle>Tour statistics</TextSectionTitle>
            <SearchCustom
              placeholder="Search tour name, uploader, place"
              allowClear
              enterButton="Search"
              size="middle"
              onSearch={(e) => onSearch(e)}
            />
          </ColumnSearchSection>
        </RowSearchSection>
      </ContainerSearchSection>

      <ContainerTable>
        {/* Information */}
        <RowInformationSection>
          <IconInfo />
          <TextInformation>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
            ad minim veniam.
          </TextInformation>
        </RowInformationSection>

        {/* Table */}
        <TableContent
          dataSource={tourStatisticsTableDataSource}
          columns={columns}
          pagination={false}
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          rowKey={(record) => record.id}
          loading={tourStatisticsLoading}
        />
        <ContainerPagiantion>
          <Pagination
            onChange={setPage}
            pageSize={pageSize}
            total={
              pageCount !== undefined ? pageCount * pageSize : 1 * pageSize
            }
            current={page}
            showSizeChanger={false}
          />
        </ContainerPagiantion>
      </ContainerTable>
    </ContainerTableContent>
  );
};

export default TourStatisticsScreen;

import * as React from 'react';
import {useHistory} from 'react-router-dom';
import {Divider, Button, Space} from 'antd';
import {
  ContainerContent,
  TextPageTitle,
  RowActionButtons,
  InputCustom,
} from '../../styled';
import {
  ContainerDataSection,
  TextSectionTitle,
  RowInputFields,
  DatePickerCustom,
  ContainerInput,
  TextInputLabel,
} from './styled';
import moment from 'moment';
import {} from '../login-screen/styled';
import Loading from '../../components/loading/loading';

import useProfileScreen from './hooks/use-profile-screen';

const ProfileScreen = (): JSX.Element => {
  const history = useHistory();
  const {userData, userDataLoading} = useProfileScreen();

  if (userDataLoading) {
    return <Loading />;
  }

  return (
    <ContainerContent>
      <TextPageTitle>{userData?.adminProfil.name}</TextPageTitle>

      {/* Personal data section */}
      <ContainerDataSection>
        <TextSectionTitle>Personal data</TextSectionTitle>
        <Divider />
        <RowInputFields>
          <ContainerInput>
            <TextInputLabel>Name</TextInputLabel>
            <InputCustom
              width={30}
              value={userData?.adminProfil.name}
              allowClear
              disabled
            />
          </ContainerInput>
          <ContainerInput>
            <TextInputLabel>Nationality</TextInputLabel>
            <InputCustom
              width={30}
              value={userData?.adminProfil.nationality}
              allowClear
              disabled
            />
          </ContainerInput>
        </RowInputFields>
        <RowInputFields>
          <ContainerInput>
            <TextInputLabel>Birthday</TextInputLabel>
            <DatePickerCustom
              value={moment(userData?.adminProfil.birthday)}
              allowClear
              disabled
            />
          </ContainerInput>
          <ContainerInput>
            <TextInputLabel>Birthplace</TextInputLabel>
            <InputCustom
              width={30}
              value={userData?.adminProfil.birthplace}
              allowClear
              disabled
            />
          </ContainerInput>
        </RowInputFields>
      </ContainerDataSection>

      {/* Contract data section */}
      <ContainerDataSection>
        <TextSectionTitle>Contract data</TextSectionTitle>
        <Divider />
        <RowInputFields>
          <ContainerInput>
            <TextInputLabel>Country</TextInputLabel>
            <InputCustom
              width={18}
              value={userData?.adminProfil.country}
              allowClear
              disabled
            />
          </ContainerInput>
          <ContainerInput>
            <TextInputLabel>City</TextInputLabel>
            <InputCustom
              width={18}
              value={userData?.adminProfil.city}
              allowClear
              disabled
            />
          </ContainerInput>
          <ContainerInput>
            <TextInputLabel>Address</TextInputLabel>
            <InputCustom
              width={27}
              value={userData?.adminProfil.address}
              allowClear
              disabled
            />
          </ContainerInput>
        </RowInputFields>
        <RowInputFields>
          <ContainerInput>
            <TextInputLabel>Phone number</TextInputLabel>
            <InputCustom
              width={18}
              value={userData?.adminProfil.phone}
              allowClear
              disabled
            />
          </ContainerInput>
          <ContainerInput>
            <TextInputLabel>Email address</TextInputLabel>
            <InputCustom
              width={22}
              value={userData?.adminProfil.email}
              allowClear
              disabled
            />
          </ContainerInput>
          <ContainerInput>
            <TextInputLabel>Tax number</TextInputLabel>
            <InputCustom
              width={15}
              value={userData?.adminProfil.taxNumber}
              allowClear
              disabled
            />
          </ContainerInput>
        </RowInputFields>
      </ContainerDataSection>

      {/* Action buttons */}
      <RowActionButtons>
        <Space size={15}>
          <Button onClick={() => history.goBack()}>Back</Button>
        </Space>
      </RowActionButtons>
    </ContainerContent>
  );
};

export default ProfileScreen;

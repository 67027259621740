import {useState, useEffect} from 'react';
import {gql, useLazyQuery, useMutation} from '@apollo/client';
import {
  adminListTours,
  adminListToursVariables,
} from './__generated__/adminListTours';
import {
  adminDeleteTour,
  adminDeleteTourVariables,
} from './__generated__/adminDeleteTour';
import {Modal} from 'antd';

const useToursScreen = () => {
  const [page, setPage] = useState<number>(1);
  const pageSize = 50;

  const [getTours, {data: toursData, loading: toursLoading}] = useLazyQuery<
    adminListTours,
    adminListToursVariables
  >(
    gql`
      query adminListTours($paginator: Paginator!, $filter: TourFilter!) {
        adminListTours(paginator: $paginator, filter: $filter) {
          pageCount
          tours {
            id
            name
            country
            approved
            active
            rate
          }
        }
      }
    `,
    {
      variables: {
        paginator: {
          page: page,
          perPage: pageSize,
        },
        filter: {
          keyword: '',
          approved: undefined,
        },
      },
      fetchPolicy: 'network-only',
    },
  );

  const [deleteTour, {error: deleteTourError, loading: deleteTourLoading}] =
    useMutation<adminDeleteTour, adminDeleteTourVariables>(gql`
      mutation adminDeleteTour($tourId: Int!) {
        adminDeleteTour(tourId: $tourId)
      }
    `);

  /* Query new data on load and page change */
  useEffect(() => {
    getTours({
      variables: {
        paginator: {
          page: page,
          perPage: pageSize,
        },
        filter: {
          keyword: '',
          approved: undefined,
        },
      },
    });
  }, [page]);

  const onSearch = (e: string) => {
    getTours({
      variables: {
        paginator: {
          page: page,
          perPage: pageSize,
        },
        filter: {
          keyword: e,
          approved: undefined,
        },
      },
    });
  };

  const onDeleteTour = (tourIdCurrent: number) => {
    deleteTour({
      variables: {
        tourId: tourIdCurrent,
      },
    }).then(() => {
      getTours({
        variables: {
          paginator: {
            page: page,
            perPage: pageSize,
          },
          filter: {
            keyword: '',
            approved: undefined,
          },
        },
      });
    });
  };

  useEffect(() => {
    if (deleteTourError) {
      Modal.error({
        title: 'Error delete tour',
        content: 'Something went wrong, tour deletion failed!',
        onOk() {
          () => Modal.destroyAll();
        },
      });
    }
  }, [deleteTourError]);

  return {
    toursData,
    toursLoading,
    setPage,
    page,
    pageSize,
    onSearch,
    onDeleteTour,
    deleteTourLoading,
  };
};

export default useToursScreen;

import {useEffect} from 'react';
import {gql, useMutation} from '@apollo/client';
import {Modal} from 'antd';
import {
  adminSetPlaceActive,
  adminSetPlaceActiveVariables,
} from './__generated__/adminSetPlaceActive';

const usePlaceActivate = (
  placeIdCurrent: number | undefined,
  activationValue: boolean,
  setPlaceActive: (value: boolean) => void,
) => {
  const [
    activatePlace,
    {
      data: activatePlaceData,
      loading: activatePlaceLoading,
      error: activatePlaceError,
    },
  ] = useMutation<adminSetPlaceActive, adminSetPlaceActiveVariables>(
    gql`
      mutation adminSetPlaceActive($placeId: Int!, $value: Boolean!) {
        adminSetPlaceActive(placeId: $placeId, value: $value)
      }
    `,
  );

  const onActivate = () => {
    if (placeIdCurrent !== undefined) {
      activatePlace({
        variables: {
          placeId: placeIdCurrent,
          value: !activationValue,
        },
      });
    } else {
      Modal.error({
        title: 'Error in place activation/inactivation',
        content: 'There is no place id',
      });
    }
  };

  /* Error handling on activate */
  useEffect(() => {
    if (activatePlaceError) {
      Modal.error({
        title: 'Error in place activation/inactivation',
        content: 'Something went wrong, place activation/inactivation failed!',
        onOk() {
          () => Modal.destroyAll();
        },
      });
    }
    if (activatePlaceData !== undefined && activatePlaceData !== null) {
      if (activatePlaceData.adminSetPlaceActive) {
        setPlaceActive(!activationValue);
        Modal.success({
          content: `Place ${
            activationValue ? 'inactivation' : 'activation'
          } succeeded`,
        });
      }
    }
  }, [activatePlaceData, activatePlaceError]);

  return {onActivate, activatePlaceLoading};
};

export default usePlaceActivate;

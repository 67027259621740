const domain = 'https://turisztika.test.konstruktor.hu';
//const domain = 'http://localhost:8080';
const BACKEND_URL = domain;
const WS_API_URL = domain + '/query';
const GRAPHQL_API_URL = domain + '/query';

// TODO: frontend img install/authorization in query ?authorization=

const config = {
  backend_url: BACKEND_URL,
  ws_api_url: WS_API_URL,
  graphql_api_url: GRAPHQL_API_URL,
};

export default config;

import React from 'react';
import {useDispatch} from 'react-redux';
import Auth from '../../../logic/redux/auth';
import {gql, useMutation} from '@apollo/client';
import {Modal} from 'antd';
import {adminLogout} from './__generated__/adminLogout';
import {adminStripeRefresh} from './__generated__/adminStripeRefresh';
import {adminStripe} from './__generated__/adminStripe';
import {useHistory, useLocation} from 'react-router-dom';
import {RouteNames, SubRouteNames} from '../../../routes/path';

const useUpperMenu = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  const [logUserOut, {data: logOutData, loading: logOutDataLoading}] =
    useMutation<adminLogout>(
      gql`
        mutation adminLogout {
          adminLogout
        }
      `,
    );

  const [stripeCall, {data: stripeData, loading: stripeLoading}] =
    useMutation<adminStripe>(
      gql`
        mutation adminStripe {
          adminStripe {
            url
            isLogin
          }
        }
      `,
    );

  const [
    stripeRefresh,
    {data: stripeRefreshData, loading: stripeRefreshLoading},
  ] = useMutation<adminStripeRefresh>(
    gql`
      mutation adminStripeRefresh {
        adminStripeRefresh
      }
    `,
  );

  React.useEffect(() => {
    if (stripeData) {
      const newWindow = window.open(
        stripeData.adminStripe.url,
        '_blank',
        'noopener,noreferrer',
      );
      if (newWindow !== null) newWindow.opener = null;
      //window.location.replace(stripeData.adminStripe.url);
    }
  }, [stripeData]);

  React.useEffect(() => {
    if (logOutData !== undefined && logOutData !== null) {
      if (logOutData.adminLogout) {
        dispatch(Auth.actions.setUserLoggedIn(false));
        dispatch(
          Auth.actions.setAdminUser({
            name: '',
            role: '',
          }),
        );
      }
    }
  }, [logOutData]);

  React.useEffect(() => {
    if (history.location.pathname === SubRouteNames.STRIPE) {
      stripeRefresh()
        .then(
          (data) =>
            data.data && window.location.replace(data.data.adminStripeRefresh),
        )
        .catch(() => history.push(RouteNames.MAIN));
    }
  }, [history.location.pathname]);

  const onLogout = () => {
    logUserOut().catch((e) => {
      console.log(e);
      Modal.error({
        title: 'Error in log out',
        content: 'Something went wrong, while logging out!',
      });
    });
  };

  const onStripe = () => {
    console.log('Stripe click');
    stripeCall().catch((e) => console.log(e));
  };

  return {onLogout, onStripe, stripeLoading};
};

export default useUpperMenu;

import * as React from 'react';
import {
  ContainerTableContent,
  ContainerSearchSection,
  RowSearchSection,
  TextSectionTitle,
  ColumnSearchSection,
  ContainerTable,
  TableContent,
  ContainerPagiantion,
  RowInformationSection,
  IconInfo,
  TextInformation,
} from '../../styled';
import {Button, Modal, Pagination} from 'antd';
import useNotificationScreen from './hooks/use-notification-screen';

interface Notifications {
  id: number;
  title: string;
  text: string;
  createdAt: string;
}

const NotificationScreen = (): JSX.Element => {
  const {notificationData, notificationLoading, setPage, page, pageSize} =
    useNotificationScreen();

  const notifications =
    notificationData?.adminListNotifications.notifications || [];
  const pageCount = notificationData?.adminListNotifications.pageCount;

  const notificationTableDataSource: Notifications[] = notifications.map(
    (notification) => ({
      id: notification.id,
      title: notification.title,
      text: notification.text,
      createdAt: notification.createdAt,
    }),
  );

  /* TODO delete mock data */
  /* const dataSource = [
    {
      title: 'Túra elfogadva',
      createdAt: '2022.01.10',
      text: 'A Szépművészeti túrád elfogadta az admin, most már aktiválhatod.',
    },
    {
      title: 'Túra aktiválva',
      createdAt: '2022.01.12',
      text: 'A Szépművészeti túrád aktiválva lett, most már elérhető a mobil appban.',
    },
  ]; */

  const columns = [
    {
      title: 'Message subject',
      dataIndex: 'title',
      key: 'title',
    },
    {
      title: 'Date',
      dataIndex: 'createdAt',
      key: 'createdAt',
    },
    {
      dataIndex: 'text',
      key: 'text',
      render: (record: Notifications) => {
        return (
          <Button
            type="primary"
            onClick={() => {
              Modal.info({
                content: <span>{record}</span>,
                onOk() {
                  null;
                },
              });
            }}>
            Details
          </Button>
        );
      },
    },
  ];

  return (
    <ContainerTableContent>
      {/* Search bar section */}
      <ContainerSearchSection>
        <RowSearchSection>
          <ColumnSearchSection>
            <TextSectionTitle>Notifications</TextSectionTitle>
          </ColumnSearchSection>
        </RowSearchSection>
      </ContainerSearchSection>

      <ContainerTable>
        {/* Information */}
        <RowInformationSection>
          <IconInfo />
          <TextInformation>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
            ad minim veniam.
          </TextInformation>
        </RowInformationSection>

        {/* Table */}
        <TableContent
          dataSource={notificationTableDataSource}
          columns={columns}
          pagination={false}
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          rowKey={(record) => record}
          loading={notificationLoading}
        />

        {/* Pagination */}
        <ContainerPagiantion>
          <Pagination
            onChange={setPage}
            pageSize={pageSize}
            total={
              pageCount !== undefined ? pageCount * pageSize : 1 * pageSize
            }
            current={page}
            showSizeChanger={false}
          />
        </ContainerPagiantion>
      </ContainerTable>
    </ContainerTableContent>
  );
};

export default NotificationScreen;

import {useEffect} from 'react';
import {useHistory} from 'react-router-dom';
import {gql, useMutation} from '@apollo/client';
import {Modal} from 'antd';
import {
  adminDeletePlaceFromPage,
  adminDeletePlaceFromPageVariables,
} from './__generated__/adminDeletePlaceFromPage';

const usePlaceDelete = (placeIdCurrent: number | undefined) => {
  const history = useHistory();
  const [
    deletePlace,
    {
      data: deletePlaceData,
      loading: deletePlaceLoading,
      error: deletePlaceError,
    },
  ] = useMutation<adminDeletePlaceFromPage, adminDeletePlaceFromPageVariables>(
    gql`
      mutation adminDeletePlaceFromPage($placeId: Int!) {
        adminDeletePlace(placeId: $placeId)
      }
    `,
  );

  const onDelete = () => {
    if (placeIdCurrent !== undefined) {
      deletePlace({
        variables: {
          placeId: placeIdCurrent,
        },
      });
    } else {
      Modal.error({
        title: 'Error delete place',
        content: 'There is no place id',
      });
    }
  };

  /* Error handling on delete */
  useEffect(() => {
    if (deletePlaceError) {
      Modal.error({
        title: 'Error delete place',
        content: 'Something went wrong, place deletion failed!',
        onOk() {
          () => Modal.destroyAll();
        },
      });
    }
    if (deletePlaceData !== undefined && deletePlaceData !== null) {
      if (deletePlaceData.adminDeletePlace) {
        Modal.success({
          content: 'Place was deleted successfully!',
          onOk: () => history.goBack(),
        });
      }
    }
  }, [deletePlaceData, deletePlaceError]);

  return {onDelete, deletePlaceLoading};
};

export default usePlaceDelete;

import {action} from 'typesafe-actions';

export const actionTypes = {
  RESET_MENU_ARRAY: 'app/ResetMenuArray',
};

export const actions = {
  resetMenuArray: (payload: boolean) =>
    action(actionTypes.RESET_MENU_ARRAY, payload),
};

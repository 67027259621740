import React, {useEffect} from 'react';
import {gql, useMutation} from '@apollo/client';
import {notification} from 'antd';
import {
  adminForgotPassword,
  adminForgotPasswordVariables,
} from './__generated__/adminForgotPassword';

const useForgotPassword = () => {
  //   const dispatch = useDispatch();

  const [
    forgotPasswordCall,
    {
      data: forgotPasswordData,
      loading: forgotPasswordLoading,
      error: forgotPasswordError,
    },
  ] = useMutation<adminForgotPassword, adminForgotPasswordVariables>(gql`
    mutation adminForgotPassword($email: String!) {
      adminForgotPassword(email: $email)
    }
  `);

  useEffect(() => {
    if (forgotPasswordError) {
      switch (forgotPasswordError.message) {
        /* TODO UPDATE */
        /* case 'wrong_email_or_password':
          notification['error']({
            message: 'Wrong email address!',
          });
          break; */
        default:
          notification['error']({
            message: 'Error in forgot password!',
          });
      }
    }
  }, [forgotPasswordError]);

  React.useEffect(() => {
    if (forgotPasswordData) {
      //   dispatch(Auth.actions.setUserLoggedIn(true));
      //   dispatch(Auth.actions.setShouldResetClient(true));
    }
  }, [forgotPasswordData]);

  return {forgotPasswordCall, forgotPasswordLoading, forgotPasswordError};
};

export default useForgotPassword;

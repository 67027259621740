import styled from 'styled-components';
import {
  ColorUpperMenuBackground,
  ColorWhite,
  ColorLinkBlue,
} from '../../styled';
import {Button} from 'antd';
import {SearchOutlined, CaretDownOutlined} from '@ant-design/icons';

export const ContainerUpperMenu = styled.div`
  height: 50px;
  width: 100vw;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background: ${ColorUpperMenuBackground};
`;

export const ContainerUpperRightMenu = styled.div`
  display: flex;
  flex-direction: row;
  gap: 40px;
`;

export const TextBrand = styled.h1`
  font-size: 18px;
  font-weight: 700;
  margin: 0 0 0 22px;
  color: ${ColorWhite};
`;

export const ContainerSearchAndUser = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  margin: 0 60px 0 0;
`;

export const ButtonSearch = styled(Button)`
  margin: 0 15px 0 0;
  height: auto;
`;

export const IconSearch = styled(SearchOutlined)`
  color: ${ColorWhite};
  font-size: 21px;
  :hover {
    color: ${ColorLinkBlue};
  }
`;

export const ContainerDropDown = styled.div`
  cursor: pointer;
  :hover :first-child {
    color: ${ColorLinkBlue};
  }
`;

export const TextUser = styled.span`
  font-size: 14px;
  font-weight: 400;
  color: ${ColorWhite};
`;

export const IconDown = styled(CaretDownOutlined)`
  margin: 0 0 0 10px;
  font-size: 14px;
  color: ${ColorWhite};
`;

import {useState, useRef} from 'react';

const usePlaceAutoComplete = () => {
  const [placeResult, setPlaceResult] = useState(null);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const selectRef = useRef<null | any>(null);

  return {placeResult, setPlaceResult, selectRef};
};

export default usePlaceAutoComplete;

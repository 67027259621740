import React from 'react';
import {useSelector} from 'react-redux';
import {Redirect, Route, RouteProps} from 'react-router-dom';
import {IStore} from '../../logic/redux/IStore';

const AdminRoute = (
  props: RouteProps & {redirectPathName: string},
): JSX.Element => {
  const userRole = useSelector((state: IStore) => state.auth.adminUser?.role);

  if (userRole === 'ADMIN' || userRole === 'CONTENT_ADMIN') {
    return <Route {...props} />;
  } else {
    const redirect = (): JSX.Element => (
      <Redirect to={{pathname: props.redirectPathName}} />
    );
    return <Route {...props} component={redirect} render={undefined} />;
  }
};

export default AdminRoute;

import {useEffect} from 'react';
import {gql, useMutation} from '@apollo/client';
import {Modal} from 'antd';
import {
  adminSetPlaceApproved,
  adminSetPlaceApprovedVariables,
} from './__generated__/adminSetPlaceApproved';

const usePlaceApprove = (
  placeIdCurrent: number | undefined,
  approvedValue: boolean,
  setPlaceApproved: (value: boolean) => void,
) => {
  const [
    approvePlace,
    {
      data: approvePlaceData,
      loading: approvePlaceLoading,
      error: approvePlaceError,
    },
  ] = useMutation<adminSetPlaceApproved, adminSetPlaceApprovedVariables>(
    gql`
      mutation adminSetPlaceApproved($placeId: Int!, $value: Boolean!) {
        adminSetPlaceApproved(placeId: $placeId, value: $value)
      }
    `,
  );

  const onApprove = () => {
    if (placeIdCurrent !== undefined) {
      approvePlace({
        variables: {
          placeId: placeIdCurrent,
          value: !approvedValue,
        },
      });
    } else {
      Modal.error({
        title: 'Error approve/disapproved place',
        content: 'There is no place id',
      });
    }
  };

  /* Error handling on approve */
  useEffect(() => {
    if (approvePlaceError) {
      Modal.error({
        title: 'Error approve/disapproved place',
        content: 'Something went wrong, approve/disapproved place failed!',
        onOk() {
          () => Modal.destroyAll();
        },
      });
    }
    if (approvePlaceData !== undefined && approvePlaceData !== null) {
      if (approvePlaceData.adminSetPlaceApproved) {
        setPlaceApproved(!approvedValue);
        Modal.success({
          content: `Place has ${
            approvedValue ? 'disapproved' : 'approved'
          } successfully`,
        });
      }
    }
  }, [approvePlaceData, approvePlaceError]);

  return {onApprove, approvePlaceLoading};
};

export default usePlaceApprove;

import {useState, useEffect} from 'react';
import {gql, useLazyQuery, useMutation} from '@apollo/client';
import {
  adminListAdminUsers,
  adminListAdminUsersVariables,
} from './__generated__/adminListAdminUsers';
import {
  adminDeleteAdminUser,
  adminDeleteAdminUserVariables,
} from './__generated__/adminDeleteAdminUser';
import {Modal} from 'antd';

const useUserScreen = () => {
  const [page, setPage] = useState<number>(1);
  const pageSize = 50;

  const [
    getUsers,
    {data: usersData, loading: usersLoading, error: usersError},
  ] = useLazyQuery<adminListAdminUsers, adminListAdminUsersVariables>(
    gql`
      query adminListAdminUsers(
        $paginator: Paginator!
        $filter: AdminUserFilter!
      ) {
        adminListAdminUsers(paginator: $paginator, filter: $filter) {
          pageCount
          adminUsers {
            id
            name
            email
          }
        }
      }
    `,
    {
      variables: {
        paginator: {
          page: page,
          perPage: pageSize,
        },
        filter: {
          keyword: '',
        },
      },
      fetchPolicy: 'network-only',
    },
  );

  const [deleteUser, {loading: deleteUserLoading, error: errorDeleteUser}] =
    useMutation<adminDeleteAdminUser, adminDeleteAdminUserVariables>(gql`
      mutation adminDeleteAdminUser($userId: UUID!) {
        adminDeleteAdminUser(userId: $userId)
      }
    `);

  /* Query new data on load and page change */
  useEffect(() => {
    getUsers({
      variables: {
        paginator: {
          page: page,
          perPage: pageSize,
        },
        filter: {
          keyword: '',
        },
      },
    });
  }, [page]);

  const onSearch = (e: string) => {
    getUsers({
      variables: {
        paginator: {
          page: page,
          perPage: pageSize,
        },
        filter: {
          keyword: e,
        },
      },
    });
  };

  const onDeleteUser = (userIdCurrent: string) => {
    deleteUser({
      variables: {
        userId: userIdCurrent,
      },
    }).then(() => {
      getUsers({
        variables: {
          paginator: {
            page: page,
            perPage: pageSize,
          },
          filter: {
            keyword: '',
          },
        },
      });
    });
  };

  /* Error handling on save */
  useEffect(() => {
    if (errorDeleteUser) {
      Modal.error({
        title: 'Something went wrong!',
        content: 'User delete was failed, please try again or contact us.',
        onOk() {
          () => Modal.destroyAll();
        },
      });
    }
  }, [errorDeleteUser]);

  return {
    usersData,
    usersError,
    usersLoading,
    setPage,
    page,
    pageSize,
    onSearch,
    onDeleteUser,
    deleteUserLoading,
  };
};

export default useUserScreen;

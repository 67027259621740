import {gql, useMutation} from '@apollo/client';
import {Modal} from 'antd';
import {useEffect} from 'react';
import {
  adminSetTourApproved,
  adminSetTourApprovedVariables,
} from './__generated__/adminSetTourApproved';

const useTourApprove = (
  tourIdCurrent: number | undefined,
  approvedValue: boolean,
  setTourApproved: (value: boolean) => void,
) => {
  const [
    approveTour,
    {
      data: approveTourData,
      loading: approveTourLoading,
      error: approveTourError,
    },
  ] = useMutation<adminSetTourApproved, adminSetTourApprovedVariables>(
    gql`
      mutation adminSetTourApproved($tourId: Int!, $value: Boolean!) {
        adminSetTourApproved(tourId: $tourId, value: $value)
      }
    `,
  );

  const onApprove = () => {
    if (tourIdCurrent !== undefined) {
      approveTour({
        variables: {
          tourId: tourIdCurrent,
          value: !approvedValue,
        },
      });
    } else {
      Modal.error({
        title: 'Error approve/disapproved tour',
        content: 'There is no tour id',
      });
    }
  };

  /* Error handling on approve */
  useEffect(() => {
    if (approveTourError) {
      Modal.error({
        title: 'Error approve/disapproved tour',
        content: 'Something went wrong, approve/disapproved tour failed!',
        onOk() {
          () => Modal.destroyAll();
        },
      });
    }

    if (approveTourData !== undefined && approveTourData !== null) {
      if (approveTourData.adminSetTourApproved) {
        setTourApproved(!approvedValue);
        Modal.success({
          content: `Tour has ${
            approvedValue ? 'disapproved' : 'approved'
          } successfully`,
        });
      }
    }
  }, [approveTourData, approveTourError]);

  return {onApprove, approveTourLoading};
};

export default useTourApprove;

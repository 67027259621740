import * as React from 'react';
import {Form, Input} from 'antd';
import {Redirect, useHistory} from 'react-router-dom';
import useLogin from '../hooks/use-login';
import {
  ButtonForgotPassword,
  ButtonForm,
  FormItemCustom,
  IconInputPassword,
  IconInputUser,
  LoginForm,
} from '../styled';
import Loading from '../../../components/loading/loading';
import {RouteNames} from '../../../routes/path';

const Login = (): JSX.Element => {
  const {loggedIn, loginLoading, loginError, redirectPath, loginCall} =
    useLogin();

  const history = useHistory();

  const layout = {
    labelCol: {span: 5},
    wrapperCol: {span: 15},
  };
  const tailLayout = {
    wrapperCol: {offset: 5, span: 15},
  };

  if (loggedIn === true && redirectPath !== undefined) {
    return <Redirect to={redirectPath} />;
  } else if (loggedIn === true) {
    return <Redirect to={RouteNames.MAIN} />;
  }

  if (loginLoading) {
    return <Loading />;
  }

  return (
    <LoginForm error={loginError !== undefined}>
      <Form
        {...layout}
        name="basic"
        initialValues={{remember: true}}
        onFinish={(values) => {
          loginCall({
            variables: {
              email: values.email,
              password: values.password,
            },
          }).catch((e) => {
            console.log(e);
          });
        }}>
        <Form.Item
          label="Email"
          name="email"
          rules={[
            {required: true, type: 'email', message: 'Email is not correct!'},
            {required: true, message: 'Email is required!'},
          ]}>
          <Input
            placeholder="email"
            allowClear={true}
            prefix={<IconInputUser />}
          />
        </Form.Item>

        <FormItemCustom
          label="Password"
          name="password"
          rules={[{required: true, message: 'Password required!'}]}>
          <Input.Password
            placeholder="password"
            prefix={<IconInputPassword />}
          />
        </FormItemCustom>
        {/*renderError()*/}

        <FormItemCustom wrapperCol={{offset: 5, span: 15}}>
          <ButtonForgotPassword
            type="link"
            onClick={() => history.push(RouteNames.FORGOTPASSWORD)}>
            Forgot password?
          </ButtonForgotPassword>
        </FormItemCustom>

        <Form.Item {...tailLayout}>
          <ButtonForm type="primary" htmlType="submit">
            Log in
          </ButtonForm>
        </Form.Item>
      </Form>
    </LoginForm>
  );
};

export default Login;

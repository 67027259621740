import React from 'react';
import {useHistory} from 'react-router-dom';

import {useSelector, useDispatch} from 'react-redux';
import {actions} from '../../logic/redux/app/app-actions';

import {IStore} from '../../logic/redux/IStore';
import {
  ContainerUpperMenu,
  TextBrand,
  ContainerSearchAndUser,
  TextUser,
  IconDown,
  ContainerDropDown,
  ContainerUpperRightMenu,
} from './styled';
import {Menu, Dropdown, Button, Spin} from 'antd';
import useUpperMenu from './hooks/use-upper-menu';
import {SubRouteNames} from '../../routes/path';

const UpperMenu = (): JSX.Element => {
  const history = useHistory();
  const dispatch = useDispatch();
  const adminUser = useSelector((state: IStore) => state.auth.adminUser);
  const {onLogout, onStripe, stripeLoading} = useUpperMenu();

  const dropDownUpperMenu = (
    <Menu>
      <Menu.Item
        key="1"
        onClick={() => {
          dispatch(actions.resetMenuArray(true));
          history.push('/profile');
        }}>
        <Button type="link" onClick={() => history.push(SubRouteNames.PROFILE)}>
          Profile
        </Button>
      </Menu.Item>
      <Menu.Item key="2">
        <Button type="link" onClick={() => onLogout()}>
          Sign out
        </Button>
      </Menu.Item>
    </Menu>
  );
  return (
    <ContainerUpperMenu>
      <TextBrand>WanderFox admin</TextBrand>
      <ContainerUpperRightMenu>
        {/*adminUser?.role === 'CONTENT_UPLOADER' &&
          (stripeLoading ? (
            <Spin />
          ) : (
            <Button onClick={() => onStripe()}>Stripe</Button>
          ))*/}

        <ContainerSearchAndUser>
          <Dropdown overlay={dropDownUpperMenu}>
            <ContainerDropDown>
              <TextUser>{adminUser ? adminUser.name : 'Anonymous'}</TextUser>
              <IconDown />
            </ContainerDropDown>
          </Dropdown>
        </ContainerSearchAndUser>
      </ContainerUpperRightMenu>
    </ContainerUpperMenu>
  );
};

export default UpperMenu;

import {gql, useMutation} from '@apollo/client';
import {Modal} from 'antd';
import {useEffect} from 'react';
import {
  adminSetTourActive,
  adminSetTourActiveVariables,
} from './__generated__/adminSetTourActive';

const useTourActivate = (
  tourIdCurrent: number | undefined,
  activationValue: boolean,
  setTourActive: (value: boolean) => void,
) => {
  const [
    activateTour,
    {
      data: activateTourData,
      loading: activateTourLoading,
      error: activateTourError,
    },
  ] = useMutation<adminSetTourActive, adminSetTourActiveVariables>(
    gql`
      mutation adminSetTourActive($tourId: Int!, $value: Boolean!) {
        adminSetTourActive(tourId: $tourId, value: $value)
      }
    `,
  );

  const onActivate = () => {
    if (tourIdCurrent !== undefined) {
      activateTour({
        variables: {
          tourId: tourIdCurrent,
          value: !activationValue,
        },
      });
    } else {
      Modal.error({
        title: 'Error in tour activation/inactivation',
        content: 'There is no tour id',
      });
    }
  };

  /* Error handling on activate */
  useEffect(() => {
    if (activateTourError) {
      Modal.error({
        title: 'Error in tour activation/inactivation',
        content: 'Something went wrong, tour activation/inactivation failed!',
        onOk() {
          () => Modal.destroyAll();
        },
      });
    }
    if (activateTourData !== undefined && activateTourData !== null) {
      if (activateTourData.adminSetTourActive) {
        setTourActive(!activationValue);
        Modal.success({
          content: `Tour ${
            activationValue ? 'inactivation' : 'activation'
          } succeeded`,
        });
      }
    }
  }, [activateTourData, activateTourError]);

  return {onActivate, activateTourLoading};
};

export default useTourActivate;

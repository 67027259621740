import styled from 'styled-components';
import {ColorWhite} from '../../styled';

export const ContainerMain = styled.div`
  height: 100%;
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  background: ${ColorWhite};
`;

export const RowSideMenuAndContent = styled.div`
  height: 100%;
  width: 100vw;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
`;

import * as React from 'react';
import {useHistory} from 'react-router-dom';
import {
  ContainerTableContent,
  ContainerSearchSection,
  RowSearchSection,
  TextSectionTitle,
  ColumnSearchSection,
  ButtonAdd,
  ContainerTable,
  SearchCustom,
  PlusIcon,
  TableContent,
  ContainerPagiantion,
} from '../../styled';
import {Button, Modal, Pagination} from 'antd';
import {DeleteOutlined, ExclamationCircleOutlined} from '@ant-design/icons';
import useUserScreen from './hooks/use-user-screen';
import {SubRouteNames} from '../../routes/path';
import Loading from '../../components/loading/loading';

const {confirm} = Modal;

interface Users {
  id: string;
  name: string;
  email: string;
}

const UsersScreen = (): JSX.Element => {
  const history = useHistory();
  const {
    usersData,
    usersLoading,
    setPage,
    page,
    pageSize,
    onSearch,
    onDeleteUser,
    deleteUserLoading,
  } = useUserScreen();

  const users = usersData?.adminListAdminUsers.adminUsers || [];
  const pageCount = usersData?.adminListAdminUsers.pageCount;

  const usersTableDataSource: Users[] = users.map((user) => ({
    id: user.id,
    name: user.name,
    email: user.email,
  }));

  const columns = [
    {
      title: 'User name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Email address',
      dataIndex: 'email',
      key: 'email',
    },
    {
      dataIndex: 'id',
      key: 'id',
      render: (record: string) => {
        return (
          <Button
            type="primary"
            onClick={() => {
              history.push(`/users/${record}`);
            }}>
            Details
          </Button>
        );
      },
    },
    {
      dataIndex: 'delete',
      key: 'delete',
      render: (record: string) => {
        return (
          <Button
            danger
            icon={<DeleteOutlined />}
            onClick={() =>
              confirm({
                title: 'Delete user',
                icon: <ExclamationCircleOutlined />,
                content: 'Are you sure to delete user?',
                onOk() {
                  onDeleteUser(record);
                },
                onCancel() {
                  Modal.destroyAll();
                },
                okText: 'Yes',
                cancelText: 'Cancel',
              })
            }
          />
        );
      },
    },
  ];

  if (deleteUserLoading) {
    return <Loading />;
  }

  return (
    <ContainerTableContent>
      {/* Search bar section */}
      <ContainerSearchSection>
        <RowSearchSection>
          <ColumnSearchSection>
            <TextSectionTitle>Users</TextSectionTitle>
            <SearchCustom
              placeholder="Search name or email"
              allowClear
              enterButton="Search"
              size="middle"
              onSearch={(e) => onSearch(e)}
            />
          </ColumnSearchSection>
          <ButtonAdd
            type="primary"
            icon={<PlusIcon />}
            onClick={() => history.push(SubRouteNames.USER_NEW)}>
            Add user
          </ButtonAdd>
        </RowSearchSection>
      </ContainerSearchSection>

      {/* Table */}
      <ContainerTable>
        <TableContent
          dataSource={usersTableDataSource}
          columns={columns}
          pagination={false}
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          rowKey={(record) => record.id}
          loading={usersLoading}
        />
        <ContainerPagiantion>
          <Pagination
            onChange={setPage}
            pageSize={pageSize}
            total={
              pageCount !== undefined ? pageCount * pageSize : 1 * pageSize
            }
            current={page}
            showSizeChanger={false}
          />
        </ContainerPagiantion>
      </ContainerTable>
    </ContainerTableContent>
  );
};

export default UsersScreen;

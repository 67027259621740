import {
  ApolloClient,
  from,
  HttpLink,
  InMemoryCache,
  NormalizedCacheObject,
  split,
} from '@apollo/client';
import {setContext} from '@apollo/client/link/context';
import {WebSocketLink} from '@apollo/client/link/ws';
import {getMainDefinition} from '@apollo/client/utilities';
import {onError} from '@apollo/client/link/error';
import {notification} from 'antd';

/* import {useHistory} from 'react-router-dom';
import {RouteNames} from '../routes/routes';
import {history} from '../logic/redux/store'; */

/* TODO - need to update */
const isDevelopment =
  !process.env.NODE_ENV || process.env.NODE_ENV === 'development';
const backendURL =
  process.env.REACT_APP_API !== undefined
    ? process.env.REACT_APP_API
    : 'test.retour.hu:8080';

export const API_URL = isDevelopment ? backendURL : 'https://' + backendURL;

const GRAPHQL_API_URL = isDevelopment
  ? 'http://' + backendURL + '/query'
  : 'https://' + backendURL + '/query';

const token = sessionStorage.getItem('token');

const WS_API_URL = isDevelopment
  ? 'ws://' + backendURL + '/query'
  : 'wss://' + backendURL + '/query';
//const token = sessionStorage.getItem('token');

const wsClient = new WebSocketLink({
  uri: WS_API_URL,
  options: {
    reconnect: true,
    connectionParams: {
      headers: {
        Authorization: token !== null ? token : '',
      },
    },
  },
});

const httpLink = new HttpLink({
  uri: GRAPHQL_API_URL,
  credentials: 'include',
});

const authLink = setContext((_, {headers}) => {
  const token = sessionStorage.getItem('token');
  return {
    headers: {
      ...headers,
      Authorization: token !== null ? token : '',
    },
  };
});

const link = split(
  // split based on operation type
  ({query}) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === 'OperationDefinition' &&
      definition.operation === 'subscription'
    );
  },
  wsClient,
  authLink.concat(httpLink),
);

const errorLink = onError(({graphQLErrors, networkError}) => {
  if (graphQLErrors)
    graphQLErrors.forEach(({message, locations, path}) => {
      console.log(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
      );
      /*if (message === "unauthorized") {
        history.push(RouteNames.LOGIN);
      }*/
      const msg = errorMsg(message);
      if (msg !== '') {
        notification['error']({
          message: 'Error',
          description: msg,
        });
      } else {
        notification['error']({
          message: 'Error',
          description: 'Something went wrong',
        });
      }
    });

  if (networkError) console.log(`[Network error]: ${networkError}`);
});

const errorMsg = (message: string) => {
  switch (message) {
    case 'database error':
      return 'Database error';
    case 'missing user from ctx':
      return 'Missing user from context';
    case 'internal error':
      return 'Internal server error';
    case 'unauthorized':
      return 'Unauthorized';
    case 'invalid email or password':
      return 'Invalid email or password';
    case 'name too short':
      return 'Name too short';
    case 'invalid email':
      return 'Invalid email';
    case 'email already exists':
      return 'Email already exists';
    case 'admin user not found':
      return 'Admin user not found';
    case 'tour not found':
      return 'Tour not found';
    case 'place not found':
      return 'Place not found';
    case 'content language not found':
      return 'Content language not found';
    case 'invalid audio file':
      return 'Invalid audio file';
    case 'invalid audio file id':
      return 'Invalid audio file id';
    case 'image required':
      return 'Image required';
    case 'audio required':
      return 'Audio required';
    case 'invalid image file':
      return 'Invalid image file';
    case 'official and comission is checked':
      return 'Official and comission is checked';
    case 'invalid commission percent':
      return 'Invalid commission percent';
    case 'user interaction already running':
      return 'User interaction already running';
    case 'payout not processable too small amount':
      return 'Payout not processable too small amount';
    case 'payment already paid out':
      return 'Payment already paid out';
    case 'payout csv missing':
      return 'Payout csv missing';
    case 'unprocessed_payment_not_found':
      return 'Unprocessed payout not found';
    default:
      return '';
  }
};

export const createGraphqlClient = (): ApolloClient<NormalizedCacheObject> =>
  new ApolloClient({
    cache: new InMemoryCache(),
    link: from([errorLink, link]),
    defaultOptions: {
      watchQuery: {
        fetchPolicy: 'cache-and-network',
        errorPolicy: 'all',
      },
      query: {
        fetchPolicy: 'network-only',
        errorPolicy: 'all',
      },
      mutate: {
        errorPolicy: 'all',
      },
    },
  });

let graphqlClient = createGraphqlClient();

export const resetGraphqlClient = (): ApolloClient<NormalizedCacheObject> => {
  const newClient = createGraphqlClient();
  graphqlClient = newClient;
  return newClient;
};

export default graphqlClient;

import {useState, useEffect} from 'react';
import {useHistory} from 'react-router-dom';
import {Form, message, Modal} from 'antd';
import {gql, useLazyQuery, useMutation} from '@apollo/client';
import {adminTour, adminTourVariables} from './__generated__/adminTour';
import {adminListContentLanguages} from './__generated__/adminListContentLanguages';
import {
  adminSetTour,
  adminSetTourVariables,
} from './__generated__/adminSetTour';
import {SubRouteNames} from '../../../routes/path';
import {IImageFile} from '../../../data-types/interfaces';
import {getBase64} from '../../../helpers/file.util';

interface AudioObject {
  name: string;
  id: string;
  base64: string;
}

const useTourDetailsScreen = (
  tourIdCurrent: number | undefined,
  placeIdCurrent: number | undefined,
) => {
  const history = useHistory();
  const [form] = Form.useForm();
  const [audioArray, setAudioArray] = useState<AudioObject[]>([]);
  const [newAudioFileTitle, setNewAudioFileTitle] = useState<string>('');
  const [isTourActive, setIsTourActive] = useState<boolean>(false);
  const [isTourApproved, setIsTourApproved] = useState<boolean>(false);
  const [imageValid, setImageValid] = useState<boolean | undefined>();
  const [audioValid, setAudioValid] = useState<boolean | undefined>();
  const [imageFormFieldValue, setImageFormFieldValue] = useState<IImageFile>({
    name: '',
    id: '',
    base64: '',
  });

  const [
    tourCreate,
    {data: tourCreateData, loading: tourCreateLoading, error: tourCreateError},
  ] = useMutation<adminSetTour, adminSetTourVariables>(
    gql`
      mutation adminSetTour($tour: InputAdminTour!) {
        adminSetTour(tour: $tour)
      }
    `,
  );

  const [getTourData, {data: tourData, loading: tourDataLoading}] =
    useLazyQuery<adminTour, adminTourVariables>(
      gql`
        query adminTour($tourId: Int!) {
          adminTour(tourId: $tourId) {
            placeId
            id
            name
            country
            language {
              id
              name
              translations
            }
            description
            approved
            active
            image {
              name
              id
              base64
            }
            audios {
              name
              id
              base64
            }
            createdBy {
              name
            }
            createdAt
            downloadedCount
          }
        }
      `,
      {
        variables: {
          tourId: tourIdCurrent !== undefined ? tourIdCurrent : 0, // TODO update 0
        },
        fetchPolicy: 'network-only',
      },
    );

  const [getContentLanguages, {data: contentLanguages}] =
    useLazyQuery<adminListContentLanguages>(
      gql`
        query adminListContentLanguages {
          adminListContentLanguages {
            id
            name
            translations
          }
        }
      `,
      {
        fetchPolicy: 'network-only',
      },
    );

  /* Effects on load */
  useEffect(() => {
    // Navigate back to places in case of new tour creation without placeid */
    if (
      history.location.pathname === SubRouteNames.TOUR_NEW &&
      placeIdCurrent === undefined
    ) {
      history.push(SubRouteNames.PLACES);
    }

    // Query tour data
    if (tourIdCurrent !== undefined && tourIdCurrent !== 0) {
      getTourData({
        variables: {
          tourId: tourIdCurrent,
        },
      });
    }
    getContentLanguages();
  }, []);

  /* Fill audio array from gql data */
  useEffect(() => {
    if (tourData !== undefined) {
      const newAudioArray: AudioObject[] = [];
      // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
      if (tourData.adminTour.audios && tourData.adminTour.audios.length > 0) {
        for (const newAudioData of tourData.adminTour.audios) {
          newAudioArray.push({
            name: newAudioData.name,
            id: newAudioData.id,
            base64: newAudioData.base64,
          });
        }
        setAudioValid(true);
      }
      setAudioArray(newAudioArray);
    }

    form.resetFields();
  }, [tourData]);

  /* Fill form with image data for preview in edit mode */
  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
    if (tourData && tourData.adminTour.image) {
      const value: {image: IImageFile} = {
        image: {
          name: tourData?.adminTour.image.name,
          id: tourData?.adminTour.image.id,
          base64: tourData?.adminTour.image.base64,
        },
      };

      form.setFieldsValue(value);
      setImageFormFieldValue(value.image);
      setImageValid(true);
    }
  }, [tourData?.adminTour.image]);

  /* Fill form with sample audio data for preview in edit mode */
  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
    if (tourData && tourData.adminTour.audios) {
      form.setFieldsValue({
        audio: tourData.adminTour.audios,
      });
    }
  }, [tourData?.adminTour.audios]);

  /* Update isTourActive state for activate button */
  useEffect(() => {
    if (tourData && tourData.adminTour.active) {
      setIsTourActive(tourData.adminTour.active);
    }
  }, [tourData?.adminTour.active]);

  /* Update isTourApproved state for approved button */
  useEffect(() => {
    if (tourData && tourData.adminTour.approved) {
      setIsTourApproved(tourData.adminTour.approved);
    }
  }, [tourData?.adminTour.approved]);

  function beforeUpload(
    file: File,
    type: 'image' | 'sampleAudio' | 'audio',
    name?: string,
  ) {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    const isM4a =
      file.type === 'audio/m4a' ||
      file.type === 'audio/x-m4a' ||
      file.type === 'audio/mp4';

    getBase64(file, (imageUrl: string) => {
      if (type === 'image') {
        if (!isJpgOrPng) {
          message.error('You can only upload JPG/PNG file!');
        } else {
          const value: {image: IImageFile} = {
            image: {
              name: '', // for image upload backend doesn't need name
              id: '',
              base64: imageUrl,
            },
          };
          form.setFieldsValue(value);
          setImageFormFieldValue(value.image);
          setImageValid(true);
        }
      } else if (type === 'audio') {
        if (!isM4a) {
          message.error('You can only upload m4a file!');
        } else {
          addAudioToList(name != null ? name : 'Audio', '', imageUrl as string);
        }
      }
    });
    return false;
  }

  const addAudioToList = (name: string, id: string, base64: string) => {
    const newAudioArray: AudioObject[] | undefined = Object.assign(
      [],
      audioArray,
    );
    newAudioArray.push({
      name: name,
      id: id,
      base64: base64,
    });
    setAudioArray(newAudioArray);
    setNewAudioFileTitle('');
    setAudioValid(true);
  };

  const swapAudioInList = (index: number, direction: 'up' | 'down') => {
    const newAudioArray: AudioObject[] | undefined = Object.assign(
      [],
      audioArray,
    );

    if (newAudioArray !== undefined) {
      if (direction === 'down') {
        [newAudioArray[index], newAudioArray[index + 1]] = [
          newAudioArray[index + 1],
          newAudioArray[index],
        ];
      }
      if (direction === 'up') {
        [newAudioArray[index], newAudioArray[index - 1]] = [
          newAudioArray[index - 1],
          newAudioArray[index],
        ];
      }
    }
    setAudioArray(newAudioArray);
  };

  const deleteAudioFromList = (index: number) => {
    const newAudioArray: AudioObject[] | undefined = Object.assign(
      [],
      audioArray,
    );
    newAudioArray.splice(index, 1);
    setAudioArray(newAudioArray);

    if (newAudioArray.length === 0) {
      setAudioValid(false);
    }
  };

  const validateOnSubmit = () => {
    imageValid === undefined && setImageValid(false);
    audioValid === undefined && setAudioValid(false);
  };

  const onFinishTour = (values: {
    name: string;
    description: string;
    language: number;
  }) => {
    if (imageValid === true && audioValid === true) {
      tourCreate({
        variables: {
          tour: {
            placeId: tourData
              ? tourData.adminTour.placeId
              : placeIdCurrent != undefined
              ? placeIdCurrent
              : 0,
            id: tourData ? tourData.adminTour.id : 0,
            name: values.name,
            languageId: values.language,
            description: values.description,
            image: form.getFieldValue('image'),
            audios: audioArray !== undefined ? audioArray : [],
          },
        },
      });
    }
  };

  /* Error handling on save */
  useEffect(() => {
    if (tourCreateError) {
      if (tourCreateError.message === 'invalid audio file') {
        Modal.error({
          title: 'Invalid audio file format!',
          content:
            'Please check the audio file. It has to be in pure .m4a format. We are sorry but files converted from .mp4 or .mp3 can not be accepted.',
          onOk() {
            () => Modal.destroyAll();
          },
        });
      } else {
        Modal.error({
          title: 'Something went wrong!',
          content: 'Save tour was failed, please try again or contact us.',
          onOk() {
            () => Modal.destroyAll();
          },
        });
      }
    }

    if (tourCreateData !== undefined && tourCreateData !== null) {
      if (tourCreateData.adminSetTour) {
        history.goBack();
      }
    }
  }, [tourCreateData, tourCreateError]);

  return {
    tourCreateLoading,
    tourData,
    tourDataLoading,
    form,
    beforeUpload,
    audioArray,
    onFinishTour,
    swapAudioInList,
    deleteAudioFromList,
    newAudioFileTitle,
    setNewAudioFileTitle,
    isTourActive,
    setIsTourActive,
    isTourApproved,
    setIsTourApproved,
    imageValid,
    audioValid,
    validateOnSubmit,
    imageFormFieldValue,
    contentLanguages,
  };
};

export default useTourDetailsScreen;

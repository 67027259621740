import React from 'react';
import {useHistory, useLocation} from 'react-router-dom';
import {
  ContainerContent,
  RowActionButtons,
  TextInformation,
  TextPageTitle,
  InputCustom,
  SelectCustom,
  ContainerInformation,
  ContainerSection,
  RowItems,
  FormItemCustom,
  TextTitle,
  TextData,
  TextAreaCustom,
  UploadCustom,
  TextNotValid,
} from '../../styled';
import {
  TextAudioTitle,
  ContainerAudioComponent,
  ContainerAudios,
  RowAudioFile,
  DividerAudios,
  ContainerBottom,
  TagSample,
  RowFormTitle,
  TextRequiredStar,
} from './styled';
import {
  Button,
  Divider,
  Space,
  Select,
  Upload,
  Form,
  Tooltip,
  Modal,
} from 'antd';
import {
  DeleteOutlined,
  DownloadOutlined,
  ExclamationCircleOutlined,
  UploadOutlined,
} from '@ant-design/icons';
import useTourDetailsScreen from './hooks/use-tour-details-screen';
import {SubRouteNames} from '../../routes/path';
import Loading from '../../components/loading/loading';
import useTourActivate from './hooks/use-tour-activate';
import useTourApprove from './hooks/use-tour-approve';
import useTourDelete from './hooks/use-tour-delete';
import formLabel from '../../components/form-label/form-label';
import {useSelector} from 'react-redux';
import {IStore} from '../../logic/redux/IStore';
import PreviewButton from '../../components/image-preview-button/image-preview-button';
import UploadImage from '../../components/upload-image/upload-image';
import config from '../../io/api/config/api-config';

const {Option} = Select;
const {confirm} = Modal;

const TourDetailsScreen = (): JSX.Element => {
  const history = useHistory();
  const userRole = useSelector((state: IStore) => state.auth.adminUser?.role);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const location: any = useLocation();
  const tourIdCurrent: number | undefined =
    history.location.pathname !== SubRouteNames.TOUR_NEW
      ? Number(history.location.pathname.replace(SubRouteNames.TOURS + '/', ''))
      : undefined;
  const placeIdCurrent: number | undefined =
    location.state !== undefined ? location.state.placeId : undefined;

  /* Tour details hook */
  const {
    tourCreateLoading,
    tourData,
    tourDataLoading,
    form,
    beforeUpload,
    audioArray,
    onFinishTour,
    swapAudioInList,
    deleteAudioFromList,
    newAudioFileTitle,
    setNewAudioFileTitle,
    isTourActive,
    setIsTourActive,
    isTourApproved,
    setIsTourApproved,
    validateOnSubmit,
    imageValid,
    audioValid,
    imageFormFieldValue,
    contentLanguages,
  } = useTourDetailsScreen(tourIdCurrent, placeIdCurrent);

  /* Activate tour hook */
  const {onActivate, activateTourLoading} = useTourActivate(
    tourIdCurrent,
    isTourActive,
    setIsTourActive,
  );

  /* Approve tour hook */
  const {onApprove, approveTourLoading} = useTourApprove(
    tourIdCurrent,
    isTourApproved,
    setIsTourApproved,
  );

  /* Delete tour hook */
  const {onDelete, deleteTourLoading} = useTourDelete(tourIdCurrent);

  const layout = {
    labelCol: {span: 100},
    wrapperCol: {span: 200},
  };

  const renderAudioComponent = () => {
    if (audioArray !== undefined) {
      if (audioArray.length === 0) {
        return (
          <>
            <TextAudioTitle>No audio uploaded</TextAudioTitle>
            <ContainerBottom />
          </>
        );
      } else {
        return audioArray.map((audioObject, index) => {
          return (
            <ContainerAudioComponent key={index}>
              <RowAudioFile>
                <Space size={15}>
                  <TextAudioTitle>{index + 1}.</TextAudioTitle>
                  <TextAudioTitle>{audioObject.name}</TextAudioTitle>
                  {index === 0 && <TagSample color="green">Sample</TagSample>}
                </Space>
                <Space>
                  {/* Down */}
                  {index !== audioArray.length - 1 && (
                    <Button onClick={() => swapAudioInList(index, 'down')}>
                      &darr;
                    </Button>
                  )}
                  {/* Up */}
                  {index !== 0 && (
                    <Button onClick={() => swapAudioInList(index, 'up')}>
                      &uarr;
                    </Button>
                  )}
                  {/* Download */}
                  <Button
                    type="primary"
                    href={`${config.backend_url}/content/tour/${tourData?.adminTour.id}/audio/${audioObject.id}`}
                    download
                    icon={<DownloadOutlined />}
                  />
                  {/* Delete */}
                  <Button
                    danger
                    onClick={() => deleteAudioFromList(index)}
                    icon={<DeleteOutlined />}
                  />
                </Space>
              </RowAudioFile>
              {audioArray.length > 1 && audioArray.length - 1 !== index ? (
                <DividerAudios dashed />
              ) : (
                <ContainerBottom />
              )}
            </ContainerAudioComponent>
          );
        });
      }
    }
  };

  if (
    tourDataLoading ||
    tourCreateLoading ||
    activateTourLoading ||
    approveTourLoading ||
    deleteTourLoading
  ) {
    return <Loading />;
  }

  return (
    <ContainerContent>
      <TextPageTitle>
        {history.location.pathname === SubRouteNames.TOUR_NEW
          ? 'New Tour'
          : tourData?.adminTour.name}
      </TextPageTitle>

      {/* Information */}
      {history.location.pathname !== SubRouteNames.TOUR_NEW && (
        <>
          <RowItems>
            <TextTitle>Place identifier:</TextTitle>
            <TextData>{tourData?.adminTour.placeId}</TextData>
          </RowItems>
          <RowItems>
            <TextTitle>Created by:</TextTitle>
            <TextData>{tourData?.adminTour.createdBy.name}</TextData>
          </RowItems>
          <RowItems>
            <TextTitle>Created at:</TextTitle>
            <TextData>{tourData?.adminTour.createdAt}</TextData>
          </RowItems>
          <RowItems>
            <TextTitle>Country:</TextTitle>
            <TextData>{tourData?.adminTour.country}</TextData>
          </RowItems>
          <RowItems>
            <TextTitle>Download count:</TextTitle>
            <TextData>{tourData?.adminTour.downloadedCount}</TextData>
          </RowItems>
        </>
      )}

      <ContainerInformation>
        <TextInformation>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
          minim veniam, quis nostrud exercitation ullamco laboris nisi ut
          aliquip ex ea commodo consequat.
        </TextInformation>
      </ContainerInformation>
      <Divider />

      <Form
        {...layout}
        form={form}
        name="basic"
        initialValues={{remember: true}}
        onFinish={(values) => {
          onFinishTour(values);
        }}>
        {/* Tour name */}
        <RowItems>
          <FormItemCustom
            label={formLabel('Tour name', 'Lorem ipsum dolor sit amet')}
            name="name"
            initialValue={tourData?.adminTour.name}
            rules={[
              {
                required: true,
                min: 3,
                message: 'Name length sholud be minimum 3 character!',
              },
              {required: true, message: 'Name is required!'},
            ]}>
            <InputCustom width={50} placeholder="Your tour name" allowClear />
          </FormItemCustom>
        </RowItems>
        <Divider />

        {/* Tour language */}
        <RowItems>
          <FormItemCustom
            label={formLabel('Tour language', 'Lorem ipsum dolor sit amet')}
            name="language"
            initialValue={tourData?.adminTour.language.id}
            rules={[{required: true, message: 'Language is required!'}]}>
            <SelectCustom width={50} onChange={() => null}>
              {contentLanguages !== undefined &&
                contentLanguages.adminListContentLanguages.map((lang) => {
                  return (
                    <Option key={lang.id} value={lang.id}>
                      {lang.translations['en']}
                    </Option>
                  );
                })}
            </SelectCustom>
          </FormItemCustom>
        </RowItems>
        <Divider />

        {/* Tour description */}
        <RowItems>
          <FormItemCustom
            label={formLabel(
              'Tour description (max. 600 character)',
              'Lorem ipsum dolor sit amet',
            )}
            name="description"
            initialValue={tourData?.adminTour.description}
            rules={[
              {
                required: true,
                min: 3,
                message: 'Description length sholud be minimum 3 character!',
              },
              {required: true, message: 'Description is required!'},
            ]}>
            <TextAreaCustom
              width={50}
              placeholder="Your tour description"
              showCount
              maxLength={600}
            />
          </FormItemCustom>
        </RowItems>
        <Divider />

        {/* Tour image */}
        <RowItems>
          <FormItemCustom
            label={formLabel('Tour image', 'Lorem ipsum dolor sit amet')}
            shouldUpdate={true}
            rules={[{required: true, message: 'Image is required!'}]}>
            {({getFieldValue}) => (
              <UploadCustom
                listType="picture-card"
                className="avatar-uploader"
                showUploadList={false}
                beforeUpload={(file) => beforeUpload(file, 'image')}>
                <UploadImage
                  file={getFieldValue('image')}
                  url={`${config.backend_url}/content/tour/${
                    tourData?.adminTour.id
                  }/image/${getFieldValue('image')?.id}`}
                />
              </UploadCustom>
            )}
          </FormItemCustom>
          <PreviewButton
            file={imageFormFieldValue}
            url={`${config.backend_url}/content/tour/${tourData?.adminTour.id}/image/${imageFormFieldValue.id}`}
          />
        </RowItems>
        {imageValid === false && <TextNotValid>Image required!</TextNotValid>}
        <Divider />

        {/* Tour audios */}
        <ContainerSection>
          <RowFormTitle>
            <TextRequiredStar>*</TextRequiredStar>
            {formLabel('Tour audio files', 'Lorem ipsum dolor sit amet')}
          </RowFormTitle>

          <RowItems>
            <FormItemCustom
              shouldUpdate={true}
              rules={[{required: true, message: 'Sample audio is required!'}]}>
              <Space direction="vertical" size={15}>
                <InputCustom
                  width={50}
                  placeholder="Your new audio title"
                  value={newAudioFileTitle}
                  onChange={(e) => setNewAudioFileTitle(e.target.value)}
                  allowClear
                />
                <Upload
                  openFileDialogOnClick={newAudioFileTitle !== ''}
                  className="avatar-uploader"
                  showUploadList={false}
                  beforeUpload={(file) =>
                    beforeUpload(file, 'audio', newAudioFileTitle)
                  }>
                  <Tooltip title="Please add title for the audio above before upload!">
                    <Button
                      disabled={newAudioFileTitle === ''}
                      icon={<UploadOutlined />}>
                      Click to Upload
                    </Button>
                  </Tooltip>
                </Upload>
                <ContainerAudios>{renderAudioComponent()}</ContainerAudios>
              </Space>
            </FormItemCustom>
          </RowItems>
          {audioValid === false && (
            <TextNotValid>Minimum 1 audio file required!</TextNotValid>
          )}
        </ContainerSection>
        <Divider />

        {/* Action buttons */}
        <RowActionButtons>
          <Space size={15}>
            <Button
              onClick={() =>
                confirm({
                  title: 'Are you sure to go back?',
                  icon: <ExclamationCircleOutlined />,
                  content: 'Unsaved data will be lost!',
                  onOk() {
                    history.goBack();
                  },
                })
              }>
              Back
            </Button>
            <Button
              type="primary"
              htmlType="submit"
              onClick={() => validateOnSubmit()}>
              Save
            </Button>
            {history.location.pathname !== SubRouteNames.TOUR_NEW && (
              <>
                {(userRole === 'ADMIN' || userRole === 'CONTENT_ADMIN') && (
                  <Button
                    ghost
                    type="primary"
                    onClick={() => {
                      if (isTourApproved) {
                        setIsTourActive(false);
                      }
                      onApprove();
                    }}>
                    {isTourApproved ? 'Disapprove tour' : 'Approve tour'}
                  </Button>
                )}
                <Tooltip
                  title={
                    !isTourApproved && !isTourActive
                      ? 'Tour must be approved for activation'
                      : ''
                  }>
                  <Button
                    ghost
                    type="primary"
                    disabled={!isTourApproved && !isTourActive}
                    onClick={() => onActivate()}>
                    {isTourActive ? 'Inactivate tour' : 'Activate tour'}
                  </Button>
                </Tooltip>
                <Button
                  danger
                  onClick={() =>
                    confirm({
                      title: 'Are you sure to delete this tour?',
                      icon: <ExclamationCircleOutlined />,
                      content: 'This operation cannot be revoked!',
                      onOk() {
                        onDelete();
                      },
                    })
                  }>
                  Delete tour
                </Button>
              </>
            )}
          </Space>
        </RowActionButtons>
      </Form>
    </ContainerContent>
  );
};

export default TourDetailsScreen;

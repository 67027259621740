/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import {connectRouter} from 'connected-react-router';
import {combineReducers} from 'redux';
import appReducer from './app/app-reducer';
import authReducer from './auth/auth-reducer';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default (history: any) =>
  combineReducers({
    router: connectRouter(history),
    app: appReducer,
    auth: authReducer,
  });

import * as React from 'react';
import {useState} from 'react';
import {useHistory} from 'react-router-dom';
import {
  ContainerTableContent,
  ContainerSearchSection,
  RowSearchSection,
  TextSectionTitle,
  ColumnSearchSection,
  ButtonAdd,
  ContainerTable,
  SearchCustom,
  PlusIcon,
  TableContent,
  RowInformationSection,
  TextInformation,
  IconInfo,
  ContainerPagiantion,
} from '../../styled';
import {Button, Modal, Tag, Pagination, DatePicker} from 'antd';
import {
  CheckOutlined,
  CloseOutlined,
  DeleteOutlined,
  ExclamationCircleOutlined,
} from '@ant-design/icons';
import usePlacesScreen from './hooks/use-places-screen';
import {SubRouteNames} from '../../routes/path';
import Loading from '../../components/loading/loading';
import {useSelector} from 'react-redux';
import {IStore} from '../../logic/redux/IStore';
import moment from 'moment';
import config from '../../io/api/config/api-config';

const {confirm} = Modal;
const {RangePicker} = DatePicker;
interface Places {
  id: number;
  name: string;
  country: string;
  approved: boolean;
  active: boolean;
}

const PlacesScreen = (): JSX.Element => {
  const history = useHistory();
  const userRole = useSelector((state: IStore) => state.auth.adminUser?.role);
  const {
    placesData,
    placesLoading,
    setPage,
    page,
    pageSize,
    onSearch,
    onDeletePlace,
    deletePlaceLoading,
  } = usePlacesScreen();
  const places = placesData?.adminListPlaces.places || [];
  const pageCount = placesData?.adminListPlaces.pageCount;
  const placesTableDataSource: Places[] = places.map((place) => ({
    id: place.id,
    name: place.name,
    country: place.country,
    approved: place.approved,
    active: place.active,
  }));

  const [statPlace, setStatPlace] = useState<number>(0);
  const [startDate, setStartDate] = useState<moment.Moment | null>(null);
  const [endDate, setEndDate] = useState<moment.Moment | null>(null);

  const columns = [
    {
      title: 'Place name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Place id',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'Country',
      dataIndex: 'country',
      key: 'country',
    },
    {
      title: 'Status',
      dataIndex: 'approved',
      key: 'approved',
      render: (data: string) => {
        return data ? (
          <Tag color={'cyan'}>
            <CheckOutlined />
          </Tag>
        ) : (
          <Tag color={'orange'}>
            <CloseOutlined />
          </Tag>
        );
      },
    },
    {
      title: 'Active',
      dataIndex: 'active',
      key: 'active',
      render: (data: string) => {
        return data ? (
          <Tag color={'green'}>
            <CheckOutlined />
          </Tag>
        ) : (
          <Tag color={'red'}>
            <CloseOutlined />
          </Tag>
        );
      },
    },
    {
      key: 'addTour',
      render: (record: {id: number}) => {
        return (
          <Button
            onClick={() =>
              history.push({
                pathname: SubRouteNames.TOUR_NEW,
                state: {placeId: record.id},
              })
            }>
            Add tour
          </Button>
        );
      },
    },
    ...(userRole === 'ADMIN' || userRole === 'CONTENT_ADMIN'
      ? [
          {
            key: 'details',
            render: (record: {id: number}) => {
              return (
                <Button
                  type="primary"
                  onClick={() => history.push(`/places/${record.id}`)}>
                  Details
                </Button>
              );
            },
          },
        ]
      : []),
    ...(userRole === 'ADMIN' || userRole === 'CONTENT_ADMIN'
      ? [
          {
            key: 'stat',
            render: (record: {id: number}) => {
              return (
                <Button
                  type="primary"
                  onClick={() => onOpenStatModal(record.id)}>
                  Stat
                </Button>
              );
            },
          },
        ]
      : []),
    ...(userRole === 'ADMIN' || userRole === 'CONTENT_ADMIN'
      ? [
          {
            key: 'tours',
            render: (record: {id: number; name: string}) => {
              return (
                <Button
                  onClick={() =>
                    history.push({
                      pathname: `/toursofplace/${record.id}`,
                      state: {placeId: record.id, placeName: record.name},
                    })
                  }>
                  Tours
                </Button>
              );
            },
          },
        ]
      : []),
    ...(userRole === 'ADMIN' || userRole === 'CONTENT_ADMIN'
      ? [
          {
            key: 'delete',
            render: (record: {id: number}) => {
              return (
                <Button
                  danger
                  icon={<DeleteOutlined />}
                  onClick={() =>
                    confirm({
                      title: 'Delete place',
                      icon: <ExclamationCircleOutlined />,
                      content: 'Are you sure to delete this place?',
                      onOk() {
                        onDeletePlace(record.id);
                      },
                      onCancel() {
                        Modal.destroyAll();
                      },
                      okText: 'Yes',
                      cancelText: 'Cancel',
                    })
                  }
                />
              );
            },
          },
        ]
      : []),
  ];

  const onOpenStatModal = (placeIdCurrent: number) => {
    /*confirm({
      title: 'Generate Place Statistics',
      //icon: <ExclamationCircleOutlined />,
      content: 'Are you sure to delete this place?',
      
      
      onCancel() {
        Modal.destroyAll();
      },
      //okText: 'Yes',
      cancelText: 'Cancel',
    });*/
    setStatPlace(placeIdCurrent);
  };

  if (deletePlaceLoading) {
    return <Loading />;
  }

  return (
    <ContainerTableContent>
      {/* Search bar section */}
      <ContainerSearchSection>
        <RowSearchSection>
          <ColumnSearchSection>
            <TextSectionTitle>Places</TextSectionTitle>
            <SearchCustom
              placeholder="Search name, id or country"
              allowClear
              enterButton="Search"
              size="middle"
              onSearch={(e) => onSearch(e)}
            />
          </ColumnSearchSection>
          <ButtonAdd
            type="primary"
            icon={<PlusIcon />}
            onClick={() => history.push(SubRouteNames.PLACE_NEW)}>
            Add place
          </ButtonAdd>
        </RowSearchSection>
      </ContainerSearchSection>

      <ContainerTable>
        {/* Information */}
        <RowInformationSection>
          <IconInfo />
          <TextInformation>
            The list below contains the locations that are active in the app and
            it is possible to add a Tour. If the location you are looking for
            does not already exist, you can create it by clicking the Add
            button. Once it created, the admins will read the details of the
            Location you have created and once approved (you will be notified by
            email), you can add a Tour to it.
          </TextInformation>
        </RowInformationSection>

        {/* Table */}
        <TableContent
          dataSource={placesTableDataSource}
          columns={columns}
          pagination={false}
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          rowKey={(record) => record.id}
          loading={placesLoading}
        />
        <ContainerPagiantion>
          <Pagination
            onChange={setPage}
            pageSize={pageSize}
            total={
              pageCount !== undefined ? pageCount * pageSize : 1 * pageSize
            }
            current={page}
            showSizeChanger={false}
          />
        </ContainerPagiantion>
      </ContainerTable>
      <Modal
        visible={statPlace > 0}
        title="Generate commission statistics"
        onOk={() => {
          const newWindow = window.open(
            config.backend_url +
              '/place/commission?place_id=' +
              statPlace.toString() +
              '&from=' +
              startDate!.format('YYYYMMDD') +
              '&to=' +
              endDate!.format('YYYYMMDD'),
            '_blank',
            'noopener,noreferrer',
          );
          if (newWindow) newWindow.opener = null;
          setStatPlace(0);
          setStartDate(null);
          setEndDate(null);
        }}
        okButtonProps={{disabled: startDate === null || endDate === null}}
        //cancelButtonProps={{disabled: true}}
        //closable={false}
        onCancel={() => {
          setStatPlace(0);
          setStartDate(null);
          setEndDate(null);
        }}>
        <RangePicker
          value={[startDate, endDate]}
          disabledDate={(date) => moment().diff(date) <= 0}
          onChange={(dates) => {
            if (dates) {
              setStartDate(dates[0]);
              setEndDate(dates[1]);
            } else {
              setStartDate(null);
              setEndDate(null);
            }
          }}
        />
      </Modal>
    </ContainerTableContent>
  );
};

export default PlacesScreen;

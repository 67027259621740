import * as React from 'react';
import {ConatinerSpinner} from './styled';
import {Spin} from 'antd';

const Loading = (): JSX.Element => {
  return (
    <ConatinerSpinner>
      <Spin size="large" />
    </ConatinerSpinner>
  );
};

export default Loading;

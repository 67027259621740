import * as React from 'react';
import {useHistory} from 'react-router-dom';

import Loading from '../../components/loading/loading';

const StripeScreen = (): JSX.Element => {
  return <Loading />;
};

export default StripeScreen;

import {useState, useEffect} from 'react';
import {gql, useLazyQuery} from '@apollo/client';
import {
  adminListTourStatistics,
  adminListTourStatisticsVariables,
} from './__generated__/adminListTourStatistics';

const useTourStatisticsScreen = () => {
  const [page, setPage] = useState<number>(1);
  const pageSize = 50;

  const [
    getTourStatistics,
    {data: tourStatisticsData, loading: tourStatisticsLoading},
  ] = useLazyQuery<adminListTourStatistics, adminListTourStatisticsVariables>(
    gql`
      query adminListTourStatistics(
        $paginator: Paginator!
        $filter: TourStatisticsFilter!
      ) {
        adminListTourStatistics(paginator: $paginator, filter: $filter) {
          pageCount
          tourStatistics {
            id
            tourName
            uploader {
              name
            }
            placeName
            downloadCount
            totalIncome
          }
        }
      }
    `,
    {
      variables: {
        paginator: {
          page: page,
          perPage: pageSize,
        },
        filter: {
          keyword: '',
        },
      },
      fetchPolicy: 'network-only',
    },
  );

  /* Query new data on load and page change */
  useEffect(() => {
    getTourStatistics({
      variables: {
        paginator: {
          page: page,
          perPage: pageSize,
        },
        filter: {
          keyword: '',
        },
      },
    });
  }, [page]);

  const onSearch = (e: string) => {
    getTourStatistics({
      variables: {
        paginator: {
          page: page,
          perPage: pageSize,
        },
        filter: {
          keyword: e,
        },
      },
    });
  };

  return {
    tourStatisticsData,
    tourStatisticsLoading,
    setPage,
    page,
    pageSize,
    onSearch,
  };
};

export default useTourStatisticsScreen;

import * as React from 'react';
import {Route, Switch, withRouter, Router} from 'react-router';
import LoginScreen from '../screens/login-screen/login-screen';
import MainScreen from '../screens/main-screen/main-screen';
import PrivateRoute from '../components/utils/private-route';
import {useDispatch} from 'react-redux';
import Auth from '../logic/redux/auth';
import {gql, useLazyQuery} from '@apollo/client';
import {RouteNames, SubRouteNames} from './path';

import UsersScreen from '../screens/users-screen/users-screen';
import PlacesScreen from '../screens/places-screen/places-screen';
import ToursScreen from '../screens/tours-screen/tours-screen';
import ProfileScreen from '../screens/profile-screen/profile-screen';
import UserDetailsScreen from '../screens/user-details-screen/user-details-screen';
import PlaceDetailsScreen from '../screens/place-details-screen/place-details-screen';
import TourDetailsScreen from '../screens/tour-details-screen/tour-details-screen';
import {adminProfil} from './__generated__/adminProfil';
import NotificationScreen from '../screens/notification-screen/notification-screen';
import FinanceScreen from '../screens/finance-screen/finance-screen';
import TourStatisticsScreen from '../screens/tour-statistics-screen/tour-statistics-screen';
import PaymentStatisticsScreen from '../screens/payment-statistics-screen/payment-statistics-screen';
import ErrorMessagesScreen from '../screens/error-messages-screen/error-messages-screen';
import ToursOfPlaceScreen from '../screens/tours-of-place-screen/tours-of-place-screen';
import AdminRoute from '../components/utils/admin-route';
import StripeScreen from '../screens/stripe-screen/stripe-screen';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const Routes = (props: any): JSX.Element => {
  const dispatch = useDispatch();

  const [
    getAdminUser,
    {data: userData, loading: userDataLoading, error: userDataError},
  ] = useLazyQuery<adminProfil>(
    gql`
      query adminProfil {
        adminProfil {
          name
          role
        }
      }
    `,
    {
      fetchPolicy: 'network-only',
    },
  );

  React.useEffect(() => {
    getAdminUser();
  }, []);

  React.useEffect(() => {
    if (userData) {
      dispatch(Auth.actions.setUserLoggedIn(true));
      dispatch(Auth.actions.setAdminUser(userData.adminProfil));
      //return <Redirect to={RouteNames.MAIN} />;
    }
  }, [userData]);

  return (
    <div className="App">
      <Router history={props.history}>
        {userDataLoading ? null : (
          <Switch location={props.location}>
            <Route
              exact={true}
              path={RouteNames.LOGIN}
              component={LoginScreen}
            />
            <Route
              exact={true}
              path={RouteNames.FORGOTPASSWORD}
              component={LoginScreen}
            />
            <Route
              exact={true}
              path={RouteNames.SETPASSWORD}
              component={LoginScreen}
            />
            <PrivateRoute
              exact={false}
              path={RouteNames.MAIN}
              component={MainScreen}
            />
          </Switch>
        )}
      </Router>
    </div>
  );
};

export const SubRoutes = (): JSX.Element => {
  return (
    <Switch>
      <Route
        exact={true}
        path={SubRouteNames.PLACES}
        component={PlacesScreen}
      />
      <Route
        exact={true}
        path={SubRouteNames.PLACE_NEW}
        component={PlaceDetailsScreen}
      />
      <AdminRoute
        exact={true}
        path={SubRouteNames.PLACE_DETAILS}
        component={PlaceDetailsScreen}
        redirectPathName={SubRouteNames.PLACES}
      />
      <Route exact={true} path={SubRouteNames.TOURS} component={ToursScreen} />
      <Route
        exact={true}
        path={SubRouteNames.TOUR_NEW}
        component={TourDetailsScreen}
      />
      <Route
        exact={true}
        path={SubRouteNames.TOUR_DETAILS}
        component={TourDetailsScreen}
      />
      <AdminRoute
        exact={true}
        path={SubRouteNames.TOURS_OF_PLACE}
        redirectPathName={SubRouteNames.PLACES}
        component={ToursOfPlaceScreen}
      />
      <AdminRoute
        exact={true}
        path={SubRouteNames.USERS}
        component={UsersScreen}
        redirectPathName={SubRouteNames.PLACES}
      />
      <AdminRoute
        exact={true}
        path={SubRouteNames.USER_NEW}
        redirectPathName={SubRouteNames.PLACES}
        component={UserDetailsScreen}
      />
      <AdminRoute
        exact={true}
        path={SubRouteNames.USER_DETAILS}
        redirectPathName={SubRouteNames.PLACES}
        component={UserDetailsScreen}
      />
      <Route
        exact={true}
        path={SubRouteNames.NOTIFICATIONS}
        component={NotificationScreen}
      />
      <Route
        exact={true}
        path={SubRouteNames.PROFILE}
        component={ProfileScreen}
      />
      <Route
        exact={true}
        path={SubRouteNames.FINANCE}
        component={FinanceScreen}
      />
      <AdminRoute
        exact={true}
        path={SubRouteNames.TOUR_STATISTICS}
        redirectPathName={SubRouteNames.PLACES}
        component={TourStatisticsScreen}
      />
      <AdminRoute
        exact={true}
        redirectPathName={SubRouteNames.PLACES}
        path={SubRouteNames.PAYMENT_STATISTICS}
        component={PaymentStatisticsScreen}
      />
      <AdminRoute
        exact={true}
        path={SubRouteNames.ERROR_MESSAGES}
        redirectPathName={SubRouteNames.PLACES}
        component={ErrorMessagesScreen}
      />

      <Route
        exact={true}
        path={SubRouteNames.STRIPE}
        component={StripeScreen}
      />

      <Route exact={false} path={RouteNames.MAIN} component={PlacesScreen} />
    </Switch>
  );
};

export default withRouter(Routes);

import styled from 'styled-components';
import {keyframes} from 'styled-components';
import {ColorLightGray, ColorBackground, ColorWhite} from '../../styled';
import {Button, Form} from 'antd';
import {UserOutlined, LockOutlined} from '@ant-design/icons';
interface ILoginForm {
  error: boolean;
}

const shake = keyframes`
  0% { transform:  rotate(0deg); }
  30% { transform:  rotate(1deg); }
  32% { transform:  rotate(-1deg); }
  35% { transform:  rotate(0deg); }
  60% { transform:  rotate(-1deg); }
  62% { transform:  rotate(1deg); }
  65% { transform:  rotate(0deg); }
  90% { transform:  rotate(-1deg); }
  92% { transform:  rotate(1deg); }
  95% { transform:  rotate(0deg); }
`;

export const ContainerLogin = styled.div`
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: ${ColorBackground};
`;

export const LoginForm = styled.div<ILoginForm>`
  animation: ${(props) => (props.error ? shake : '')} 0.4s;
  padding: 35px 0 10px 10px;
  width: 500px;
  border: 1px solid ${ColorLightGray};
  border-radius: 10px;
  background: ${ColorWhite};
`;

export const ImageLogo = styled.img`
  width: 200px;
  height: 200px;
  margin-top: -120px;
  margin-bottom: 60px;
`;

export const ButtonForgotPassword = styled(Button)`
  padding: 0;
`;

export const FormItemCustom = styled(Form.Item)`
  margin-bottom: 10px;
`;

export const IconInputUser = styled(UserOutlined)`
  color: ${ColorLightGray};
  margin: 0 10px 0 0;
`;
export const IconInputPassword = styled(LockOutlined)`
  color: ${ColorLightGray};
  margin: 0 10px 0 0;
`;

export const ButtonForm = styled(Button)`
  width: 100%;
`;

export const TextInformation = styled.span`
  width: 500px;
  margin: 0 0 30px 0;
  padding: 0 5px;
  text-align: center;
`;

import * as React from 'react';
import {IconInfoLink, RowTitle} from './styled';
import {Tooltip} from 'antd';
import {TextTitle} from '../../styled';

const formLablel = (name: string, textTooltip: string, isIcon?: boolean) => {
  return (
    <RowTitle>
      {isIcon !== false && (
        <Tooltip title={textTooltip}>
          <IconInfoLink />
        </Tooltip>
      )}
      <TextTitle>{name}</TextTitle>
    </RowTitle>
  );
};

export default formLablel;

import styled from 'styled-components';
import {Button} from 'antd';
import {Input, Table, Select, Form, Upload} from 'antd';
import {PlusOutlined, InfoCircleOutlined} from '@ant-design/icons';

const {Search} = Input;
const {TextArea} = Input;

/* Global color definitions */
export const ColorWhite = '#ffffff';
export const ColorBackground = '#fafafd';
export const ColorUpperMenuBackground = '#001529';
export const ColorLightGray = '#bfbfbf';
export const ColorLightGrayBorder = '#f0f0f0';
export const ColorTransparentBackground = 'rgba(250, 250, 253, 0.6)';
export const ColorLinkBlue = '#1890ff';
export const ColorSearchSectionBackground = '#F0F2F5';
export const ColorRed = '#f5222d';

interface InputProps {
  width?: number;
  height?: number;
}

interface SelectProps {
  width?: number;
}

export const ContainerTableContent = styled.div`
  width: 100%;
  height: 100%;
  overflow-y: auto;
`;

export const ContainerContent = styled.div`
  width: 100%;
  height: 100%;
  padding: 20px 30px;
  overflow-y: auto;
`;

export const ContainerSearchSection = styled.div`
  width: 100%;
  padding: 30px 30px;
  background: ${ColorSearchSectionBackground};
`;

export const RowSearchSection = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
`;

export const ColumnSearchSection = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
`;

export const TextSectionTitle = styled.h2`
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  margin: 0 0 15px 0;
`;

export const ButtonAdd = styled(Button)`
  margin: 0 20px 0 0;
`;

export const ContainerTable = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 15px 20px;
`;

export const TextPageTitle = styled.h2`
  font-size: 20px;
  font-weight: 500;
  line-height: 28px;
`;

export const SearchCustom = styled(Search)`
  width: 500px;
`;

export const PlusIcon = styled(PlusOutlined)`
  font-size: 12px;
`;

export const TableContent = styled(Table)`
  width: 100%;
`;

export const RowActionButtons = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  margin: 40px 0 50px 0;
`;

export const RowInformationSection = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin: 5px 0 20px 0;
`;

export const TextInformation = styled.span`
  font-size: 16px;
  font-weight: 300;
  line-height: 24px;
`;

export const IconInfo = styled(InfoCircleOutlined)`
  font-size: 18px;
  margin: 0 15px 5px 0px;
`;

export const InputCustom = styled(Input)<InputProps>`
  margin: 5px 0 0 0;
  ${(props) =>
    props.width !== null ? `width: ${props.width}vw;` : 'width: 100%'};
`;

export const TextAreaCustom = styled(TextArea)<InputProps>`
  margin: 5px 0 0 0;
  min-width: 50vw;
  ${(props) =>
    props.width != null ? `width: ${props.width}%;` : 'width: 100%'};
  ${(props) => props.height != null && `height: ${props.height}px;`};
`;

export const SelectCustom = styled(Select)<SelectProps>`
  margin: 5px 0 0 0;
  min-width: 150px;
  ${(props) =>
    props.width != null ? `width: ${props.width}%;` : 'width: 100%'};
`;

export const UploadCustom = styled(Upload)`
  margin: 5px 0 0 0;
`;

export const ContainerInformation = styled.div`
  width: 100%;
  padding: 10px 0 0 20px;
`;

export const ContainerSection = styled.div`
  width: 100%;
  padding: 0 0 20px 0;
`;

export const ContainerPagiantion = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  margin: 12px 50px 0 0;
`;

export const RowItems = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`;

export const FormItemCustom = styled(Form.Item)`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin: 0;
  padding: 0 0 15px 0;
`;

export const TextTitle = styled.h2`
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  padding: 10px 1px 13px 1px;
  margin: 0;
`;

export const TextData = styled.span`
  font-size: 16px;
  line-height: 24px;
  font-weight: 300;
  margin: 0 0 2px 5px;
`;

export const ContainerUpload = styled.div`
  margin: 8 0 0 0;
`;

export const TextNotValid = styled.span`
  font-size: 14px;
  font-weight: 400;
  padding: 0;
  margin: 0;
  color: ${ColorRed};
`;

export const ContainerDivider = styled.div`
  height: 30px;
`;

/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import {action} from 'typesafe-actions';
import {AdminUser} from './auth-models';

export const actionTypes = {
  SET_USER_LOGGED_IN: 'Auth/SetUserLoggedIn',
  SET_LOGIN_REDIRECT_PATH: 'Auth/SetLoginRedirectPath',
  SET_SHOULD_RESET_CLIENT: 'Auth/SetShouldResetClient',
  SET_ADMIN_USER: 'Auth/setAdminUser',
};

export const actions = {
  setUserLoggedIn: (payload: boolean) =>
    action(actionTypes.SET_USER_LOGGED_IN, payload),
  setLoginRedirectPath: (path?: string) =>
    action(actionTypes.SET_LOGIN_REDIRECT_PATH, path),
  setShouldResetClient: (data: boolean) =>
    action(actionTypes.SET_SHOULD_RESET_CLIENT, data),
  setAdminUser: (payload: undefined | AdminUser) =>
    action(actionTypes.SET_ADMIN_USER, payload),
};

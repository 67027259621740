import * as React from 'react';
import retour_logo from '../../assets/icons/retour.png';
import Login from './components/login';
import {ContainerLogin, ImageLogo} from './styled';
import {useHistory} from 'react-router-dom';
import ForgotPassword from './components/forgot-password';
import SetPassword from './components/set-password';

const LoginScreen = (): JSX.Element => {
  const history = useHistory();
  const path = history.location.pathname;

  return (
    <ContainerLogin>
      <ImageLogo src={retour_logo} alt="retour logo" />

      {path === '/forgotpassword' ? (
        <ForgotPassword />
      ) : path === '/setpassword' ? (
        <SetPassword />
      ) : (
        <Login />
      )}
    </ContainerLogin>
  );
};

export default LoginScreen;

import * as React from 'react';
import {useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';
import {
  ContainerContent,
  RowActionButtons,
  TextInformation,
  TextPageTitle,
  InputCustom,
  SelectCustom,
  ContainerInformation,
  RowItems,
  FormItemCustom,
  TextTitle,
  TextData,
  TextAreaCustom,
  UploadCustom,
  TextNotValid,
} from '../../styled';
import {
  Button,
  Divider,
  Space,
  Select,
  Form,
  Modal,
  Tooltip,
  Checkbox,
  InputNumber,
} from 'antd';
import usePlaceDetailsScreen from './hooks/use-place-details-screen';
import {ExclamationCircleOutlined} from '@ant-design/icons';
import {SubRouteNames} from '../../routes/path';
import config from '../../io/api/config/api-config';
import Loading from '../../components/loading/loading';
import MapComponent from '../../components/map/map';
import PlaceAutoComplete from '../../components/place-auto-complete/place-auto-complete';
import formLabel from '../../components/form-label/form-label';
import {IStore} from '../../logic/redux/IStore';
import usePlaceActivate from './hooks/use-place-activate';
import usePlaceApprove from './hooks/use-place-approve';
import usePlaceDelete from './hooks/use-place-delete';
import UploadImage from '../../components/upload-image/upload-image';
import PreviewButton from '../../components/image-preview-button/image-preview-button';

const {Option} = Select;
const {confirm} = Modal;
const url = config.backend_url;

const PlaceDetailsScreen = (): JSX.Element => {
  const history = useHistory();
  const userRole = useSelector((state: IStore) => state.auth.adminUser?.role);
  const placeIdCurrent: number | undefined =
    history.location.pathname !== SubRouteNames.PLACE_NEW
      ? Number(
          history.location.pathname.replace(SubRouteNames.PLACES + '/', ''),
        )
      : undefined;

  const {
    placeCreateLoading,
    placeData,
    placeDataLoading,
    beforeUpload,
    onFinishPlace,
    lat,
    setLat,
    lng,
    setLng,
    clearSelect,
    setClearSelect,
    form,
    imageArray,
    imageValid,
    validateOnSubmit,
    isPlaceActive,
    setIsPlaceActive,
    isPlaceApproved,
    setIsPlaceApproved,
    isCommission,
    setIsCommission,
    commission,
    setCommission,
  } = usePlaceDetailsScreen(placeIdCurrent);

  /* Activate place hook */
  const {onActivate, activatePlaceLoading} = usePlaceActivate(
    placeIdCurrent,
    isPlaceActive,
    setIsPlaceActive,
  );

  /* Approve place hook */
  const {onApprove, approvePlaceLoading} = usePlaceApprove(
    placeIdCurrent,
    isPlaceApproved,
    setIsPlaceApproved,
  );

  /* Delete place hook */
  const {onDelete, deletePlaceLoading} = usePlaceDelete(placeIdCurrent);

  const layout = {
    labelCol: {span: 100},
    wrapperCol: {span: 200},
  };

  if (
    placeDataLoading ||
    placeCreateLoading ||
    activatePlaceLoading ||
    approvePlaceLoading ||
    deletePlaceLoading
  ) {
    return <Loading />;
  }

  return (
    <ContainerContent>
      <TextPageTitle>
        {history.location.pathname === SubRouteNames.PLACE_NEW
          ? 'New place'
          : placeData?.adminPlace.name}
      </TextPageTitle>
      {/* Information */}
      {history.location.pathname !== SubRouteNames.PLACE_NEW && (
        <>
          <RowItems>
            <TextTitle>Place identifier:</TextTitle>
            <TextData>{placeData?.adminPlace.id}</TextData>
          </RowItems>
          <RowItems>
            <TextTitle>Created by:</TextTitle>
            <TextData>{placeData?.adminPlace.createdBy.name}</TextData>
          </RowItems>
          <RowItems>
            <TextTitle>Created at:</TextTitle>
            <TextData>{placeData?.adminPlace.createdAt}</TextData>
          </RowItems>
        </>
      )}
      <ContainerInformation>
        <TextInformation>
          {history.location.pathname === SubRouteNames.PLACE_NEW
            ? `If you can't find the location you want to add a tour to in the
          Locations list, create the location here first. Fill out the form with
          the necessary information. When you are ready, save it. The admins
          will then be notified that you want to create a new Location and will
          read/verify the information you have entered. If they find everything
          OK, the location will be accepted and activated (you will be notified
          by email). You can only add a tour to the location after this.`
            : 'Information edit place'}
        </TextInformation>
      </ContainerInformation>
      <Divider />
      {/* Auto complete */}
      {formLabel(
        'Place location',
        'Enter the exact address of the Location in the search box. Click on it and you will see it marked in red on the map. If you think the marker is not accurate, tap the red marker and drag it on the map to the exact location of the Location.',
      )}
      <PlaceAutoComplete
        latChange={setLat}
        lngChange={setLng}
        clearSelect={clearSelect}
        setClearSelect={setClearSelect}
      />
      <Form
        {...layout}
        form={form}
        name="basic"
        initialValues={{remember: true}}
        onFinish={(values) => {
          onFinishPlace(values);
        }}>
        {/* Address - Map */}
        <MapComponent
          lat={lat}
          lng={lng}
          latChange={setLat}
          lngChange={setLng}
          setClearSelect={setClearSelect}
        />
        <Divider />
        {/* Country */}
        <RowItems>
          <FormItemCustom
            label={formLabel('Country', 'Enter the exact country name.')}
            name="countryName"
            initialValue={placeData?.adminPlace?.country}
            rules={[
              {
                required: true,
                min: 3,
                message: 'Country name length sholud be minimum 3 character!',
              },
              {required: true, message: 'Country name is required!'},
            ]}>
            <InputCustom width={50} placeholder="Country name" allowClear />
          </FormItemCustom>
        </RowItems>
        <Divider />
        {/* Place name */}
        <RowItems>
          <FormItemCustom
            label={formLabel(
              'Place name',
              'Enter the exact name of the Location so that people using the app can easily recognise it. For example, if you want to add a museum to your locations, then enter the official name of the museum here.',
            )}
            name="name"
            initialValue={placeData?.adminPlace.name}
            rules={[
              {
                required: true,
                min: 3,
                message: 'Name length sholud be minimum 3 character!',
              },
              {required: true, message: 'Name is required!'},
            ]}>
            <InputCustom width={50} placeholder="Your place name" allowClear />
          </FormItemCustom>
        </RowItems>
        <Divider />

        {/* Place description */}
        <RowItems>
          <FormItemCustom
            label={formLabel(
              'Place description (max. 600 character)',
              'Write a description of the site you want to create. Make sure that the description is not too long (max 600 characters) and contains the most important information about the site.',
            )}
            name="description"
            initialValue={placeData?.adminPlace.description}
            rules={[
              {
                required: true,
                min: 3,
                message: 'Description length sholud be minimum 3 character!',
              },
              {required: true, message: 'Description is required!'},
            ]}>
            <TextAreaCustom
              width={50}
              placeholder="Your place description"
              showCount
              maxLength={600}
            />
          </FormItemCustom>
        </RowItems>
        <Divider />

        {/* Place type */}
        <RowItems>
          <FormItemCustom
            label={formLabel(
              'Place type',
              'Select the type of location you want to create from the drop-down menu. If you think that the Location you want to create does not fit into one of the types, please contact the admins at adminhelp@retouraudioguide.com.',
            )}
            name="category"
            initialValue={placeData?.adminPlace.category.id}
            rules={[{required: true, message: 'Type is required!'}]}>
            <SelectCustom width={50} onChange={() => null}>
              <Option value={1}>Museum</Option>
              <Option value={2}>Building</Option>
              <Option value={3}>Park</Option>
              <Option value={4}>Entertainment</Option>
              <Option value={5}>Attraction</Option>
            </SelectCustom>
          </FormItemCustom>
        </RowItems>
        <Divider />
        {/* commission */}
        {(userRole === 'ADMIN' || userRole === 'CONTENT_ADMIN') && (
          <>
            <RowItems>
              <FormItemCustom
                label={formLabel('Place commission', '')}
                name="commission"
                //initialValue={placeData?.adminPlace.category.id}
                //</RowItems>rules={[{required: true, message: 'Type is required!'}]}>
              >
                <Checkbox
                  //value={isCommission}
                  checked={isCommission}
                  onChange={() => {
                    setIsCommission(!isCommission);
                  }}>
                  Place get commission?
                </Checkbox>
              </FormItemCustom>
            </RowItems>

            <RowItems>
              {isCommission && (
                <FormItemCustom
                  label={formLabel('Place commission percent', '')}
                  name="commissionPercent"
                  initialValue={commission}
                  rules={[
                    {
                      min: 0,
                      max: 100,
                      type: 'number',
                      message: 'min 0 max 100',
                    },
                    {
                      required: true,
                      message: 'Commission percent is required!',
                    },
                  ]}>
                  <InputNumber
                    width={50}
                    placeholder="Commission percent"
                    value={commission}
                    onChange={(val) => setCommission(val)}
                    style={{width: 250}}
                  />
                </FormItemCustom>
              )}
            </RowItems>
            <Divider />
          </>
        )}

        {[1, 2, 3, 4, 5].map((v: number, i: number): JSX.Element => {
          const firstItem = i === 0;
          return (
            <React.Fragment key={v}>
              <RowItems>
                <FormItemCustom
                  label={formLabel(
                    `Place image ${i + 1}`,
                    firstItem
                      ? 'Upload image(s) to the Location. To create a location you need to upload at least 1 image, but you can upload up to 5.'
                      : '',
                    firstItem,
                  )}
                  shouldUpdate={true}
                  rules={
                    firstItem
                      ? [{required: true, message: 'Image is required!'}]
                      : []
                  }>
                  {({getFieldValue}) => (
                    <UploadCustom
                      listType="picture-card"
                      className="avatar-uploader"
                      showUploadList={false}
                      beforeUpload={(file) => beforeUpload(file, i)}>
                      <UploadImage
                        file={
                          // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
                          getFieldValue('images')?.length &&
                          imageArray.length >= i + 1
                            ? getFieldValue('images')[i]
                            : {name: '', id: '', base64: ''}
                        }
                        url={`${url}/content/place/${
                          placeData?.adminPlace.id
                        }/image/${
                          // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
                          getFieldValue('images')?.length
                            ? getFieldValue('images')[i]?.id
                            : ''
                        }`}
                      />
                    </UploadCustom>
                  )}
                </FormItemCustom>
                <PreviewButton
                  file={
                    imageArray.length >= i + 1
                      ? imageArray[i]
                      : {name: '', id: '', base64: ''}
                  }
                  url={`${url}/content/place/${placeData?.adminPlace.id}/image/${imageArray[i]?.id}`}
                />
              </RowItems>
              {firstItem && imageValid === false && (
                <TextNotValid>Image required!</TextNotValid>
              )}
              <Divider />
            </React.Fragment>
          );
        })}

        {/* Action buttons */}
        <RowActionButtons>
          <Space size={15}>
            <Button
              onClick={() =>
                confirm({
                  title: 'Are you sure to go back?',
                  icon: <ExclamationCircleOutlined />,
                  content: 'Unsaved data will be lost!',
                  onOk() {
                    history.goBack();
                  },
                })
              }>
              Back
            </Button>
            <Button
              type="primary"
              htmlType="submit"
              onClick={() => validateOnSubmit()}>
              Save
            </Button>
            {history.location.pathname !== SubRouteNames.PLACE_NEW && (
              <>
                {(userRole === 'ADMIN' || userRole === 'CONTENT_ADMIN') && (
                  <>
                    <Button
                      ghost
                      type="primary"
                      onClick={() => {
                        if (isPlaceApproved) {
                          setIsPlaceActive(false);
                        }
                        onApprove();
                      }}>
                      {isPlaceApproved ? 'Disapprove place' : 'Approve place'}
                    </Button>

                    <Tooltip
                      title={
                        !isPlaceApproved && !isPlaceActive
                          ? 'Place must be approved for activation'
                          : ''
                      }>
                      <Button
                        ghost
                        type="primary"
                        disabled={!isPlaceApproved && !isPlaceActive}
                        onClick={() => onActivate()}>
                        {isPlaceActive ? 'Inactivate place' : 'Activate place'}
                      </Button>
                    </Tooltip>

                    <Button
                      danger
                      onClick={() =>
                        confirm({
                          title: 'Are you sure to delete this place?',
                          icon: <ExclamationCircleOutlined />,
                          content: 'This operation cannot be revoked!',
                          onOk() {
                            onDelete();
                          },
                        })
                      }>
                      Delete tour
                    </Button>
                  </>
                )}
              </>
            )}
          </Space>
        </RowActionButtons>
      </Form>
    </ContainerContent>
  );
};

export default PlaceDetailsScreen;

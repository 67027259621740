import React from 'react';
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import {geocodeByAddress, getLatLng} from 'react-google-places-autocomplete';
import {Button, Modal, Space} from 'antd';
import {ContainerAutoComplete} from './styled';
import usePlaceAutoComplete from './hooks/use-place-auto-complete';

interface PlacesAutoCompleteProps {
  latChange: (value: number) => void;
  lngChange: (value: number) => void;
  clearSelect: boolean;
  setClearSelect: (value: boolean) => void;
}

const PlacesAutoComplete = (props: PlacesAutoCompleteProps) => {
  const {placeResult, setPlaceResult, selectRef} = usePlaceAutoComplete();

  /* Clear auto complete select field */
  const onClear = () => {
    selectRef.current.select.select.clearValue();
    props.setClearSelect(false);
  };

  /* Center map if lat,lng changed (e.g.: by autocomplete) */
  React.useEffect(() => {
    if (props.clearSelect) {
      onClear();
    }
  }, [props.clearSelect]);

  return (
    <ContainerAutoComplete>
      <Space size={15}>
        <GooglePlacesAutocomplete
          // apiKey={process.env.REACT_APP_GOOGLE_API as string}  // already called in index.html
          selectProps={{
            ref: selectRef,
            clearValues: () => {
              props.latChange;
            },
            styles: {
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              input: (provided: any) => ({
                ...provided,
                width: '42vw',
              }),
            },
            placeResult,
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            onChange: (res: React.SetStateAction<any>) => {
              if (res !== null && res.value !== null) {
                setPlaceResult(res);
                geocodeByAddress(res.value.description)
                  .then((results) => getLatLng(results[0]))
                  .then(({lat, lng}) => {
                    props.latChange(lat);
                    props.lngChange(lng);
                  });
              }
            },
          }}
          onLoadFailed={(error) => {
            console.error('Could not inject Google script', error);
            Modal.error({
              title: 'Error in place search',
              content:
                'Something went wrong while searching place with google!',
            });
          }}
        />
        <Button onClick={() => onClear()}>Clear</Button>
      </Space>
    </ContainerAutoComplete>
  );
};

export default PlacesAutoComplete;

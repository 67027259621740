import {CheckOutlined, CloseOutlined} from '@ant-design/icons';
import {useHistory} from 'react-router-dom';
import {Button, Pagination, Tag} from 'antd';
import * as React from 'react';
import {useLocation} from 'react-router-dom';
import {
  ColumnSearchSection,
  ContainerPagiantion,
  ContainerSearchSection,
  ContainerTable,
  ContainerTableContent,
  IconInfo,
  RowActionButtons,
  RowInformationSection,
  RowSearchSection,
  SearchCustom,
  TableContent,
  TextInformation,
  TextSectionTitle,
} from '../../styled';
import useToursOfPlaceScreen from './hooks/use-tours-of-place-screen';

interface Tours {
  id: number;
  name: string;
  country: string;
  approved: boolean;
  active: boolean;
  rate: number;
}

const ToursOfPlaceScreen = (): JSX.Element => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const location: any = useLocation();
  const history = useHistory();
  const placeIdCurrent: number | undefined =
    location.state !== undefined ? location.state.placeId : undefined;
  const placeNameCurrent: string | undefined =
    location.state !== undefined ? location.state.placeName : undefined;

  const {
    toursOfPlaceData,
    toursOfPlaceLoading,
    setPage,
    page,
    pageSize,
    onSearch,
  } = useToursOfPlaceScreen(placeIdCurrent);
  const tours = toursOfPlaceData?.adminListToursOfPlace.tours || [];
  const pageCount = toursOfPlaceData?.adminListToursOfPlace.pageCount;
  const toursOfPlaceTableDataSource: Tours[] = tours.map((tour) => ({
    id: tour.id,
    name: tour.name,
    country: tour.country,
    approved: tour.approved,
    active: tour.active,
    rate: tour.rate,
  }));

  const columns = [
    {
      title: 'Tour name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Tour id',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'Country',
      dataIndex: 'country',
      key: 'country',
    },
    {
      title: 'Rate',
      dataIndex: 'rate',
      key: 'rate',
      render: (data: number) => {
        return <span>{data.toFixed(2)}</span>;
      },
    },
    {
      title: 'Status',
      dataIndex: 'approved',
      key: 'approved',
      render: (data: string) => {
        return data ? (
          <Tag color={'cyan'}>
            <CheckOutlined />
          </Tag>
        ) : (
          <Tag color={'orange'}>
            <CloseOutlined />
          </Tag>
        );
      },
    },
    {
      title: 'Active',
      dataIndex: 'active',
      key: 'active',
      render: (data: string) => {
        return data ? (
          <Tag color={'green'}>
            <CheckOutlined />
          </Tag>
        ) : (
          <Tag color={'red'}>
            <CloseOutlined />
          </Tag>
        );
      },
    },
  ];

  return (
    <ContainerTableContent>
      {/* Search bar section */}
      <ContainerSearchSection>
        <RowSearchSection>
          <ColumnSearchSection>
            <TextSectionTitle>{placeNameCurrent}</TextSectionTitle>
            <SearchCustom
              placeholder="Search name, id, country"
              allowClear
              enterButton="Search"
              size="middle"
              onSearch={(e) => onSearch(e)}
            />
          </ColumnSearchSection>
        </RowSearchSection>
      </ContainerSearchSection>

      <ContainerTable>
        {/* Information */}
        <RowInformationSection>
          <IconInfo />
          <TextInformation>
            The list below contains the tours you have created and the
            information related to them. You can create a new tour from the
            Locations menu, by clicking the Create Tour button in the row for
            that Location. If you want to make changes to an existing Tour, you
            can do so by clicking on the Details button in this list. If you
            make changes to a Tour, the Tour will be inactivated, i.e. it will
            not be available in the app until an admin approves the changes.
          </TextInformation>
        </RowInformationSection>

        {/* Table */}
        <TableContent
          dataSource={toursOfPlaceTableDataSource}
          columns={columns}
          pagination={false}
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          rowKey={(record) => record.id}
          loading={toursOfPlaceLoading}
        />
        <ContainerPagiantion>
          <Pagination
            onChange={setPage}
            pageSize={pageSize}
            total={
              pageCount !== undefined ? pageCount * pageSize : 1 * pageSize
            }
            current={page}
            showSizeChanger={false}
          />
        </ContainerPagiantion>
      </ContainerTable>

      {/* Action buttons */}
      <RowActionButtons>
        <Button onClick={() => history.goBack()}>Back</Button>
      </RowActionButtons>
    </ContainerTableContent>
  );
};

export default ToursOfPlaceScreen;

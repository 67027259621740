import {useState, useEffect} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {actions} from '../../../logic/redux/app/app-actions';
import {IStore} from '../../../logic/redux/IStore';
import {useHistory} from 'react-router-dom';
import {SubRouteNames} from '../../../routes/path';

const useSideMenu = () => {
  const dispatch = useDispatch();
  const [selectedKeyArray, setSelectedKeyArray] = useState<string[]>(['1']);
  const resetMenuArray = useSelector(
    (state: IStore) => state.app.resetMenuArray,
  );
  const history = useHistory();

  useEffect(() => {
    if (resetMenuArray) {
      setSelectedKeyArray([]);
      dispatch(actions.resetMenuArray(false));
    }
  }, [resetMenuArray]);

  /* Handle active side menu according to routes */
  useEffect(() => {
    switch (history.location.pathname) {
      case SubRouteNames.PLACES:
        setSelectedKeyArray(['1']);
        return;
      case SubRouteNames.PLACE_NEW:
        setSelectedKeyArray(['1']);
        return;
      case SubRouteNames.STRIPE:
        setSelectedKeyArray(['1']);
        return;
      case SubRouteNames.TOURS:
        setSelectedKeyArray(['2']);
        return;
      case SubRouteNames.TOUR_NEW:
        setSelectedKeyArray(['2']);
        return;
      case SubRouteNames.TOUR_STATISTICS:
        setSelectedKeyArray(['3']);
        return;
      case SubRouteNames.USERS:
        setSelectedKeyArray(['4']);
        return;
      case SubRouteNames.USER_DETAILS:
        setSelectedKeyArray(['4']);
        return;
      case SubRouteNames.USER_NEW:
        setSelectedKeyArray(['4']);
        return;
      case SubRouteNames.NOTIFICATIONS:
        setSelectedKeyArray(['5']);
        return;
      case SubRouteNames.FINANCE:
        setSelectedKeyArray(['6']);
        return;
      case SubRouteNames.PAYMENT_STATISTICS:
        setSelectedKeyArray(['7']);
        return;
      case SubRouteNames.ERROR_MESSAGES:
        setSelectedKeyArray(['8']);
        return;
    }
  }, [history.location.pathname]);

  return {
    selectedKeyArray,
    setSelectedKeyArray,
  };
};
export default useSideMenu;

import * as React from 'react';
import {useHistory} from 'react-router-dom';
import {Form, Input} from 'antd';
import {
  ButtonForgotPassword,
  ButtonForm,
  LoginForm,
  FormItemCustom,
  IconInputUser,
  TextInformation,
} from '../styled';
import useForgotPassword from '../hooks/use-forgot-password';
import Loading from '../../../components/loading/loading';
import {RouteNames} from '../../../routes/path';

const ForgotPassword = (): JSX.Element => {
  const history = useHistory();
  const {forgotPasswordCall, forgotPasswordLoading, forgotPasswordError} =
    useForgotPassword();
  const layout = {
    labelCol: {span: 5},
    wrapperCol: {span: 15},
  };
  const tailLayout = {
    wrapperCol: {offset: 5, span: 15},
  };

  if (forgotPasswordLoading) {
    return <Loading />;
  }

  return (
    <>
      <TextInformation>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
        tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim
        veniam.
      </TextInformation>
      <LoginForm error={forgotPasswordError !== undefined}>
        <Form
          {...layout}
          name="basic"
          initialValues={{remember: true}}
          onFinish={(values) => {
            forgotPasswordCall({
              variables: {
                email: values.email,
              },
            }).catch((e) => {
              console.log(e);
            });
          }}>
          <FormItemCustom
            label="Email"
            name="email"
            rules={[
              {required: true, type: 'email', message: 'Email is not correct!'},
              {required: true, message: 'Email is required!'},
            ]}>
            <Input
              placeholder="email"
              allowClear={true}
              prefix={<IconInputUser />}
            />
          </FormItemCustom>

          {/*renderError()*/}

          <FormItemCustom {...tailLayout}>
            <ButtonForgotPassword
              type="link"
              onClick={() => history.push(RouteNames.LOGIN)}>
              Back to login
            </ButtonForgotPassword>
          </FormItemCustom>

          <Form.Item {...tailLayout}>
            <ButtonForm type="primary" htmlType="submit">
              Send
            </ButtonForm>
          </Form.Item>
        </Form>
      </LoginForm>
    </>
  );
};

export default ForgotPassword;

const MAIN = '/';
const LOGIN = '/login';
const FORGOTPASSWORD = '/forgotpassword';
const SETPASSWORD = '/setpassword';

export const RouteNames = {
  MAIN,
  LOGIN,
  FORGOTPASSWORD,
  SETPASSWORD,
};

const PLACES = '/places';
const PLACE_NEW = '/places/new';
const PLACE_DETAILS = '/places/:id';
const TOURS = '/tours';
const TOUR_NEW = '/tours/new';
const TOUR_DETAILS = '/tours/:id';
const TOURS_OF_PLACE = '/toursofplace/:id';
const USERS = '/users';
const USER_NEW = '/users/new';
const USER_DETAILS = '/users/:id';
const PROFILE = '/profile';
const NOTIFICATIONS = '/notifications';
const ACCOUNT = '/account';
const FINANCE = '/finance';
const TOUR_STATISTICS = '/tourstatistics';
const PAYMENT_STATISTICS = '/paymentstatistics';
const ERROR_MESSAGES = '/errormessages';
const STRIPE = '/stripe';

export const SubRouteNames = {
  PLACES,
  PLACE_NEW,
  PLACE_DETAILS,
  TOURS,
  TOUR_NEW,
  TOUR_DETAILS,
  TOURS_OF_PLACE,
  USERS,
  USER_NEW,
  USER_DETAILS,
  PROFILE,
  NOTIFICATIONS,
  ACCOUNT,
  FINANCE,
  TOUR_STATISTICS,
  PAYMENT_STATISTICS,
  ERROR_MESSAGES,
  STRIPE,
};

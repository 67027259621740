import produce from 'immer';
import Auth from '.';
import {AdminUser, IAuthReducer} from './auth-models';

const initializeState = (): Auth.model.AuthReducer => ({
  loggedIn: false,
  loginRedirectPath: '',
  shouldResetClient: false,
  adminUser: undefined,
});

export default (
  state = initializeState(),
  action: {type: string; payload: Auth.model.AuthActionsPayload},
): IAuthReducer =>
  produce(state, (draft) => {
    switch (action.type) {
      case Auth.actionTypes.SET_USER_LOGGED_IN:
        draft.loggedIn = action.payload as boolean;
        return draft;
      case Auth.actionTypes.SET_LOGIN_REDIRECT_PATH:
        draft.loginRedirectPath = action.payload as string;
        return draft;
      case Auth.actionTypes.SET_SHOULD_RESET_CLIENT:
        draft.shouldResetClient = action.payload as boolean;
        return draft;
      case Auth.actionTypes.SET_ADMIN_USER:
        draft.adminUser = action.payload as AdminUser | undefined;
        return draft;

      default:
    }
  });

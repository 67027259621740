import {useEffect} from 'react';
import {gql, useLazyQuery} from '@apollo/client';
import {adminProfileMenu} from './__generated__/adminProfileMenu';

const useProfileScreen = () => {
  const [getUserData, {data: userData, loading: userDataLoading}] =
    useLazyQuery<adminProfileMenu>(
      gql`
        query adminProfileMenu {
          adminProfil {
            name
            nationality
            birthday
            birthplace
            country
            city
            address
            phone
            email
            taxNumber
          }
        }
      `,
      {
        fetchPolicy: 'network-only',
      },
    );

  /* Query user data */
  useEffect(() => {
    getUserData();
  }, []);

  return {
    userData,
    userDataLoading,
  };
};

export default useProfileScreen;

import * as React from 'react';
import {
  ContainerTableContent,
  ContainerSearchSection,
  RowSearchSection,
  TextSectionTitle,
  ColumnSearchSection,
  ContainerTable,
  TableContent,
  ContainerPagiantion,
  RowInformationSection,
  IconInfo,
  TextInformation,
} from '../../styled';
import {TextTitle, TextAmount, DividerCustom, Empty} from './styled';
import {Button, Modal, Pagination} from 'antd';
import useFinanceScreen from './hooks/use-finance-screen';
import {ExclamationCircleOutlined} from '@ant-design/icons';
import Loading from '../../components/loading/loading';
import {DateTime} from 'luxon';

const {confirm} = Modal;

interface Payments {
  date: string;
  commission: number;
}

const FinanceScreen = (): JSX.Element => {
  const {
    paymentsData,
    paymentsLoading,
    setPage,
    page,
    pageSize,
    requestPayoutLoading,
    onRequestPayout,
  } = useFinanceScreen();

  const payments = paymentsData?.adminListPayments.payments || [];
  const pageCount = paymentsData?.adminListPayments.pageCount;

  const paymentsTableDataSource: Payments[] = payments.map((payment) => ({
    date: payment.date,
    commission: payment.commission,
  }));

  const columns = [
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date',
      render: (st: string) => DateTime.fromISO(st).toFormat('yyyy.MM'),
    },
    {
      title: 'Commission',
      dataIndex: 'commission',
      key: 'commission',
    },
  ];

  if (requestPayoutLoading) {
    return <Loading />;
  }
  return (
    <ContainerTableContent>
      {/* Search bar section */}
      <ContainerSearchSection>
        <RowSearchSection>
          <ColumnSearchSection>
            <TextSectionTitle>Finance</TextSectionTitle>
          </ColumnSearchSection>
        </RowSearchSection>
      </ContainerSearchSection>

      <ContainerTable>
        {/* Information */}
        <RowInformationSection>
          <IconInfo />
          <TextInformation>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
            ad minim veniam.
          </TextInformation>
        </RowInformationSection>

        {/* Paid commission */}
        <RowInformationSection>
          <TextTitle style={{width: 350}}>Sum paid commission:</TextTitle>
          <TextAmount>
            {paymentsData?.adminListPayments.sumPaidCommission}
          </TextAmount>
        </RowInformationSection>

        {/* Requested for payout commission */}
        <RowInformationSection>
          <TextTitle style={{width: 350}}>
            Requested for payout commission:
          </TextTitle>
          <TextAmount>
            {paymentsData?.adminListPayments.sumRequestedForPayOutCommission}
          </TextAmount>
        </RowInformationSection>

        {/* Remaining commission */}
        <RowInformationSection>
          <TextTitle style={{width: 350}}>Sum remaining commission:</TextTitle>
          <TextAmount>
            {paymentsData?.adminListPayments.sumRemainingCommission}
          </TextAmount>
          <Button
            type="primary"
            disabled={
              paymentsData
                ? paymentsData.adminListPayments.sumRemainingCommission < 1000
                : true
            }
            onClick={() => {
              confirm({
                title: 'Are you sure you want the payment?',
                icon: <ExclamationCircleOutlined />,
                okText: 'Initiate payment',
                onOk() {
                  onRequestPayout();
                },
              });
            }}>
            Request payout
          </Button>
        </RowInformationSection>

        <DividerCustom />

        <DividerCustom />

        {/* Table */}
        <TableContent
          dataSource={paymentsTableDataSource}
          columns={columns}
          pagination={false}
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          rowKey={(record) => record.date}
          loading={paymentsLoading}
        />

        {/* Pagination */}
        <ContainerPagiantion>
          <Pagination
            onChange={setPage}
            pageSize={pageSize}
            total={
              pageCount !== undefined ? pageCount * pageSize : 1 * pageSize
            }
            current={page}
            showSizeChanger={false}
          />
        </ContainerPagiantion>
      </ContainerTable>
    </ContainerTableContent>
  );
};

export default FinanceScreen;

import {useState, useEffect} from 'react';
import {gql, useLazyQuery} from '@apollo/client';
import {
  adminListErrorReport,
  adminListErrorReportVariables,
} from './__generated__/adminListErrorReport';

const useErrorMessagesScreen = () => {
  const [page, setPage] = useState<number>(1);
  const pageSize = 50;

  const [
    getErrorMessages,
    {data: errorMessagesData, loading: errorMessagesLoading},
  ] = useLazyQuery<adminListErrorReport, adminListErrorReportVariables>(
    gql`
      query adminListErrorReport($paginator: Paginator!) {
        adminListErrorReport(paginator: $paginator) {
          pageCount
          errors {
            id
            createdAt
            message
            os
            phoneType
            email
            name
          }
        }
      }
    `,
    {
      variables: {
        paginator: {
          page: page,
          perPage: pageSize,
        },
      },
      fetchPolicy: 'network-only',
    },
  );

  /* Query new data on load and page change */
  useEffect(() => {
    getErrorMessages({
      variables: {
        paginator: {
          page: page,
          perPage: pageSize,
        },
      },
    });
  }, [page]);

  return {
    errorMessagesData,
    errorMessagesLoading,
    setPage,
    page,
    pageSize,
  };
};

export default useErrorMessagesScreen;

import styled from 'styled-components';
import {ColorLightGrayBorder, ColorRed} from '../../styled';
import {Divider, Tag} from 'antd';

export const TextAudioTitle = styled.h3`
  font-size: 16px;
  font-weight: 300;
  margin: 0 0 2px 0;
`;

export const ContainerAudios = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin: 15px 0 20px 0;
  border: 1px solid ${ColorLightGrayBorder};
  padding: 15px 30px 0 30px;
  border-radius: 8px;
`;

export const ContainerAudioComponent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin: 0;
`;

export const RowFormTitle = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin: 0;
`;

export const RowAudioFile = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const DividerAudios = styled(Divider)`
  margin: 10px 0;
`;

export const ContainerBottom = styled.div`
  height: 15px;
`;

export const TagSample = styled(Tag)`
  text-transform: uppercase;
  font-size: 13px;
  margin: 0 0 2px 10px;
`;

export const TextRequiredStar = styled.span`
  font-size: 12px;
  font-weight: 400;
  padding: 0;
  margin: 0 3px 0 0;
  color: ${ColorRed};
`;

import styled from 'styled-components';

export const ContainerAutoComplete = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin: 0 0 15px 0;
`;

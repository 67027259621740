import {useState, useEffect} from 'react';
import {gql, useLazyQuery} from '@apollo/client';
import {
  adminListToursOfPlace,
  adminListToursOfPlaceVariables,
} from './__generated__/adminListToursOfPlace';

const useToursOfPlaceScreen = (placeIdCurrent: number | undefined) => {
  const [page, setPage] = useState<number>(1);
  const pageSize = 50;

  const [
    getToursOfPlace,
    {data: toursOfPlaceData, loading: toursOfPlaceLoading},
  ] = useLazyQuery<adminListToursOfPlace, adminListToursOfPlaceVariables>(
    gql`
      query adminListToursOfPlace(
        $placeId: Int!
        $paginator: Paginator!
        $filter: TourFilter!
      ) {
        adminListToursOfPlace(
          placeId: $placeId
          paginator: $paginator
          filter: $filter
        ) {
          pageCount
          tours {
            id
            name
            country
            approved
            active
            rate
          }
        }
      }
    `,
    {
      variables: {
        placeId: placeIdCurrent != undefined ? placeIdCurrent : 0,
        paginator: {
          page: page,
          perPage: pageSize,
        },
        filter: {
          keyword: '',
          approved: undefined,
        },
      },
      fetchPolicy: 'network-only',
    },
  );

  /* Query new data on load and page change */
  useEffect(() => {
    getToursOfPlace({
      variables: {
        placeId: placeIdCurrent != undefined ? placeIdCurrent : 0,
        paginator: {
          page: page,
          perPage: pageSize,
        },
        filter: {
          keyword: '',
          approved: undefined,
        },
      },
    });
  }, [page]);

  const onSearch = (e: string) => {
    getToursOfPlace({
      variables: {
        placeId: placeIdCurrent != undefined ? placeIdCurrent : 0,
        paginator: {
          page: page,
          perPage: pageSize,
        },
        filter: {
          keyword: e,
          approved: undefined,
        },
      },
    });
  };

  return {
    toursOfPlaceData,
    toursOfPlaceLoading,
    setPage,
    page,
    pageSize,
    onSearch,
  };
};

export default useToursOfPlaceScreen;

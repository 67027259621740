import {useHistory} from 'react-router-dom';
import {gql, useMutation} from '@apollo/client';
import {Modal} from 'antd';
import {
  adminDeleteTourFromPage,
  adminDeleteTourFromPageVariables,
} from './__generated__/adminDeleteTourFromPage';
import {useEffect} from 'react';

const useTourDelete = (tourIdCurrent: number | undefined) => {
  const history = useHistory();
  const [
    deleteTour,
    {data: deleteTourData, loading: deleteTourLoading, error: deleteTourError},
  ] = useMutation<adminDeleteTourFromPage, adminDeleteTourFromPageVariables>(
    gql`
      mutation adminDeleteTourFromPage($tourId: Int!) {
        adminDeleteTour(tourId: $tourId)
      }
    `,
  );

  const onDelete = () => {
    if (tourIdCurrent !== undefined) {
      deleteTour({
        variables: {
          tourId: tourIdCurrent,
        },
      });
    } else {
      Modal.error({
        title: 'Error delete tour',
        content: 'There is no tour id',
      });
    }
  };

  /* Error handling on delete */
  useEffect(() => {
    if (deleteTourError) {
      Modal.error({
        title: 'Error delete tour',
        content: 'Something went wrong, tour deletion failed!',
        onOk() {
          () => Modal.destroyAll();
        },
      });
    }
    if (deleteTourData !== undefined && deleteTourData !== null) {
      if (deleteTourData.adminDeleteTour) {
        Modal.success({
          content: 'Tour was deleted successfully!',
          onOk: () => history.goBack(),
        });
      }
    }
  }, [deleteTourData, deleteTourError]);

  return {onDelete, deleteTourLoading};
};

export default useTourDelete;

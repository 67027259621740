import {useEffect} from 'react';
import {gql, useMutation, useLazyQuery} from '@apollo/client';
import {useHistory} from 'react-router-dom';
import {Modal} from 'antd';
import {
  adminAdminUser,
  adminAdminUserVariables,
} from './__generated__/adminAdminUser';
import {
  adminSetAdminUser,
  adminSetAdminUserVariables,
} from './__generated__/adminSetAdminUser';

const useUserDetailsScreen = (userIdCurrent: string | undefined) => {
  const history = useHistory();
  const [
    userCreate,
    {data: createUserData, loading: createUserLoading, error: createUserError},
  ] = useMutation<adminSetAdminUser, adminSetAdminUserVariables>(gql`
    mutation adminSetAdminUser($user: InputAdminUser!) {
      adminSetAdminUser(user: $user)
    }
  `);

  const [getUserData, {data: userData, loading: userDataLoading}] =
    useLazyQuery<adminAdminUser, adminAdminUserVariables>(
      gql`
        query adminAdminUser($userId: UUID!) {
          adminAdminUser(userId: $userId) {
            name
            nationality
            birthday
            birthplace
            country
            city
            address
            phone
            email
            taxNumber
            accountHolderName
            bankName
            iban
            swift
          }
        }
      `,
      {
        variables: {
          userId: userIdCurrent !== undefined ? userIdCurrent : '',
        },
        fetchPolicy: 'network-only',
      },
    );

  /* Query user data */
  useEffect(() => {
    if (userIdCurrent !== undefined) {
      getUserData({
        variables: {
          userId: userIdCurrent,
        },
      });
    }
  }, [userIdCurrent]);

  useEffect(() => {
    console.log('userData', userData);
  }, [userData]);

  const onFinishUser = (values: {
    name: string;
    nationality: string;
    birthday: string;
    birthplace: string;
    country: string;
    city: string;
    address: string;
    phone: string;
    email: string;
    taxNumber: string;
    accountHolderName: string;
    bankName: string;
    iban: string;
    swift: string | null;
  }) => {
    userCreate({
      variables: {
        user: {
          id: userIdCurrent !== undefined ? userIdCurrent : undefined,
          name: values.name,
          email: values.email,
          phone: values.phone,
          birthday: values.birthday,
          birthplace: values.birthplace,
          nationality: values.nationality,
          country: values.country,
          city: values.city,
          address: values.address,
          taxNumber: values.taxNumber,
          accountHolderName: values.accountHolderName,
          bankName: values.bankName,
          iban: values.iban,
          swift: values.swift,
        },
      },
    }).catch((e) => {
      console.log(e);
      Modal.error({
        title: 'Error in user create',
        content: 'Something went wrong, user creation failed!',
      });
    });
  };

  /* Error handling on save */
  useEffect(() => {
    if (createUserError) {
      Modal.error({
        title: 'Something went wrong!',
        content: 'Create/save user was failed, please try again or contact us.',
        onOk() {
          () => Modal.destroyAll();
        },
      });
    }
    if (createUserData !== undefined && createUserData !== null) {
      if (createUserData.adminSetAdminUser) {
        history.goBack();
      }
    }
  }, [createUserData, createUserError]);

  return {
    userCreate,
    createUserLoading,
    userData,
    userDataLoading,
    onFinishUser,
  };
};

export default useUserDetailsScreen;

import React from 'react';
import {useSelector} from 'react-redux';
import {ContainerSideMenu} from './styled';
import {Menu} from 'antd';
import {useHistory} from 'react-router-dom';
import {SubRouteNames} from '../../routes/path';
import {IStore} from '../../logic/redux/IStore';
import useSideMenu from './hooks/use-side-menu';

const SideMenu = (): JSX.Element => {
  const history = useHistory();
  const userRole = useSelector((state: IStore) => state.auth.adminUser?.role);
  const {selectedKeyArray, setSelectedKeyArray} = useSideMenu();

  return (
    <ContainerSideMenu>
      <Menu
        style={{width: '100%'}}
        defaultSelectedKeys={['1']}
        defaultOpenKeys={['sub1']}
        mode="inline"
        theme="light"
        selectedKeys={selectedKeyArray}>
        <Menu.Item
          key="1"
          onClick={() => {
            setSelectedKeyArray(['1']);
            history.push(SubRouteNames.PLACES);
          }}>
          Places
        </Menu.Item>
        <Menu.Item
          key="2"
          onClick={() => {
            setSelectedKeyArray(['2']);
            history.push(SubRouteNames.TOURS);
          }}>
          Tours
        </Menu.Item>
        {(userRole === 'ADMIN' || userRole === 'CONTENT_ADMIN') && (
          <Menu.Item
            key="3"
            onClick={() => {
              setSelectedKeyArray(['3']);
              history.push(SubRouteNames.TOUR_STATISTICS);
            }}>
            Tour statistics
          </Menu.Item>
        )}
        {(userRole === 'ADMIN' || userRole === 'CONTENT_ADMIN') && (
          <Menu.Item
            key="4"
            onClick={() => {
              setSelectedKeyArray(['4']);
              history.push(SubRouteNames.USERS);
            }}>
            Users
          </Menu.Item>
        )}
        {userRole === 'CONTENT_UPLOADER' && (
          <Menu.Item
            key="5"
            onClick={() => {
              setSelectedKeyArray(['5']);
              history.push(SubRouteNames.NOTIFICATIONS);
            }}>
            Notifications
          </Menu.Item>
        )}
        {userRole === 'CONTENT_UPLOADER' && (
          <Menu.Item
            key="6"
            onClick={() => {
              setSelectedKeyArray(['6']);
              history.push(SubRouteNames.FINANCE);
            }}>
            Finance
          </Menu.Item>
        )}
        {(userRole === 'ADMIN' || userRole === 'CONTENT_ADMIN') && (
          <Menu.Item
            key="7"
            onClick={() => {
              setSelectedKeyArray(['7']);
              history.push(SubRouteNames.PAYMENT_STATISTICS);
            }}>
            Payment statistics
          </Menu.Item>
        )}
        {(userRole === 'ADMIN' || userRole === 'CONTENT_ADMIN') && (
          <Menu.Item
            key="8"
            onClick={() => {
              setSelectedKeyArray(['8']);
              history.push(SubRouteNames.ERROR_MESSAGES);
            }}>
            Reported errors
          </Menu.Item>
        )}
      </Menu>
    </ContainerSideMenu>
  );
};

export default SideMenu;

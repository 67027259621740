import styled from 'styled-components';

import {ColorBackground, ColorLightGrayBorder} from '../../styled';

export const ContainerSideMenu = styled.div`
  height: 100%;
  width: 250px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  background: ${ColorBackground};
  border-right: 1px solid ${ColorLightGrayBorder};
`;

import * as React from 'react';
import {useHistory, useLocation} from 'react-router-dom';
import {Form, Input} from 'antd';
import {
  ButtonForgotPassword,
  ButtonForm,
  LoginForm,
  FormItemCustom,
  TextInformation,
  IconInputPassword,
} from '../styled';
import useSetPassword from '../hooks/use-set-password';
import Loading from '../../../components/loading/loading';
import {RouteNames} from '../../../routes/path';

const SetPassword = (): JSX.Element => {
  const history = useHistory();
  const search = useLocation().search;
  const userToken = new URLSearchParams(search).get('token');
  const {setPasswordCall, setPasswordLoading, setPasswordError} =
    useSetPassword();

  const layout = {
    labelCol: {span: 8},
    wrapperCol: {span: 12},
  };
  const tailLayout = {
    wrapperCol: {offset: 8, span: 12},
  };

  if (setPasswordLoading) {
    return <Loading />;
  }

  return (
    <>
      <TextInformation>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
        tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim
        veniam.
      </TextInformation>
      <LoginForm error={setPasswordError !== undefined}>
        <Form
          {...layout}
          name="basic"
          initialValues={{remember: true}}
          onFinish={(values) => {
            userToken !== null &&
              setPasswordCall({
                variables: {
                  updatePasswordToken: userToken,
                  password: values.password,
                },
              }).catch((e) => {
                console.log(e);
              });
          }}>
          <Form.Item
            label="Password"
            name="password"
            rules={[
              {
                required: true,
                min: 5,
                message: 'Password length sholud be minimum 5 character!',
              },
              {required: true, message: 'Password required!'},
            ]}>
            <Input.Password
              placeholder="password"
              prefix={<IconInputPassword />}
            />
          </Form.Item>

          <FormItemCustom
            label="Confirm password"
            name="confirmPassword"
            rules={[
              {
                required: true,
                min: 5,
                message: 'Password length sholud be minimum 5 character!',
              },
              ({getFieldValue}) => ({
                validator(_, value) {
                  // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
                  if (!value || getFieldValue('password') === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error(
                      'The two passwords that you entered do not match!',
                    ),
                  );
                },
              }),
              {required: true, message: 'Password required!'},
            ]}>
            <Input.Password
              placeholder="confirm password"
              prefix={<IconInputPassword />}
            />
          </FormItemCustom>
          {/*renderError()*/}

          <FormItemCustom {...tailLayout}>
            <ButtonForgotPassword
              type="link"
              onClick={() => history.push(RouteNames.LOGIN)}>
              Back to login
            </ButtonForgotPassword>
          </FormItemCustom>

          <Form.Item {...tailLayout}>
            <ButtonForm type="primary" htmlType="submit">
              Set
            </ButtonForm>
          </Form.Item>
        </Form>
      </LoginForm>
    </>
  );
};

export default SetPassword;

import styled from 'styled-components';
import {DatePicker, Form} from 'antd';

export const ContainerDataSection = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin: 70px 0 0 0;
`;

export const TextSectionTitle = styled.h3`
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  margin: 0 0 0 20px;
`;

export const RowInputFields = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0 20px 20px 0;
`;

export const DatePickerCustom = styled(DatePicker)`
  width: 30vw;
`;

export const FormItemCustom = styled(Form.Item)`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0 0 0 20px;
`;

/* eslint-disable @typescript-eslint/no-explicit-any */
import {routerMiddleware} from 'connected-react-router';
import {createBrowserHistory} from 'history';
import {applyMiddleware, compose, createStore} from 'redux';
import thunk from 'redux-thunk';
import rootReducer from './rootreducer';

export const history = createBrowserHistory();

export const store =
  process.env.NODE_ENV === 'production'
    ? createStore(
        rootReducer(history),
        compose(applyMiddleware(routerMiddleware(history), thunk)),
      )
    : createStore(
        rootReducer(history),
        compose(
          applyMiddleware(routerMiddleware(history), thunk),
          // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
          ((window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ &&
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__()) ||
            compose,
        ),
      );

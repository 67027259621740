import * as React from 'react';
import {useHistory} from 'react-router-dom';
import {Divider, Button, Space, Form, Modal} from 'antd';
import {
  ContainerContent,
  TextPageTitle,
  RowActionButtons,
  InputCustom,
} from '../../styled';
import {
  ContainerDataSection,
  TextSectionTitle,
  RowInputFields,
  DatePickerCustom,
  FormItemCustom,
} from './styled';
import useUserDetailsScreen from './hooks/use-user-details-screen';
import {} from '../login-screen/styled';
import Loading from '../../components/loading/loading';
import {SubRouteNames} from '../../routes/path';
import moment from 'moment';
import {ExclamationCircleOutlined} from '@ant-design/icons';

const {confirm} = Modal;

const UserDetailsScreen = (): JSX.Element => {
  const history = useHistory();
  const userIdCurrent =
    history.location.pathname !== SubRouteNames.USER_NEW
      ? history.location.pathname.replace(SubRouteNames.USERS + '/', '')
      : undefined;

  const {createUserLoading, userData, userDataLoading, onFinishUser} =
    useUserDetailsScreen(userIdCurrent);

  const layout = {
    labelCol: {span: 100},
    wrapperCol: {span: 200},
  };

  const initBirthday = userData
    ? moment(userData.adminAdminUser.birthday)
    : undefined;

  if (createUserLoading || userDataLoading) {
    return <Loading />;
  }

  return (
    <ContainerContent>
      <TextPageTitle>
        {history.location.pathname === SubRouteNames.USER_NEW
          ? 'New User'
          : userData?.adminAdminUser.name}
      </TextPageTitle>
      {/* Personal data section */}
      <Form
        {...layout}
        name="basic"
        initialValues={{remember: true}}
        onFinish={(values) => {
          onFinishUser(values);
        }}>
        <ContainerDataSection>
          <TextSectionTitle>Personal data</TextSectionTitle>
          <Divider />
          <RowInputFields>
            <FormItemCustom
              label="Name"
              name="name"
              initialValue={userData?.adminAdminUser.name}
              rules={[
                {
                  required: true,
                  min: 3,
                  message: 'Name length sholud be minimum 3 character!',
                },
                {required: true, message: 'Name is required!'},
              ]}>
              <InputCustom placeholder="John Doe" width={30} allowClear />
            </FormItemCustom>
            <FormItemCustom
              label="Nationality"
              name="nationality"
              initialValue={userData?.adminAdminUser.nationality}
              rules={[{required: true, message: 'Nationality is required!'}]}>
              <InputCustom placeholder="Hungarian" width={30} allowClear />
            </FormItemCustom>
          </RowInputFields>
          <RowInputFields>
            <FormItemCustom
              label="Date of birth"
              name="birthday"
              initialValue={initBirthday}
              rules={[{required: true, message: 'Date of birth is required!'}]}>
              <DatePickerCustom
                onChange={(res) => console.log('res', res)}
                allowClear
              />
            </FormItemCustom>
            <FormItemCustom
              label="Place of birth"
              name="birthplace"
              initialValue={userData?.adminAdminUser.birthplace}
              rules={[
                {required: true, message: 'Place of birth is required!'},
              ]}>
              <InputCustom placeholder="Budapest" width={30} allowClear />
            </FormItemCustom>
          </RowInputFields>
        </ContainerDataSection>

        {/* Contract data section */}
        <ContainerDataSection>
          <TextSectionTitle>Contract data</TextSectionTitle>
          <Divider />
          <RowInputFields>
            <FormItemCustom
              label="Country of residence"
              name="country"
              initialValue={userData?.adminAdminUser.country}
              rules={[
                {required: true, message: 'Country of residence is required!'},
              ]}>
              <InputCustom placeholder="Hungary" width={18} allowClear />
            </FormItemCustom>
            <FormItemCustom
              label="City of residence"
              name="city"
              initialValue={userData?.adminAdminUser.city}
              rules={[
                {required: true, message: 'City of residence is required!'},
              ]}>
              <InputCustom placeholder="Budapest" width={18} allowClear />
            </FormItemCustom>
            <FormItemCustom
              label="Address"
              name="address"
              initialValue={userData?.adminAdminUser.address}
              rules={[{required: true, message: 'Address is required!'}]}>
              <InputCustom
                placeholder="Sample street 5."
                width={27}
                allowClear
              />
            </FormItemCustom>
          </RowInputFields>
          <RowInputFields>
            <FormItemCustom
              label="Phone number"
              name="phone"
              initialValue={userData?.adminAdminUser.phone}
              rules={[{required: true, message: 'Phone number is required!'}]}>
              <InputCustom
                placeholder="+36 30 999 9999"
                width={18}
                allowClear
              />
            </FormItemCustom>
            <FormItemCustom
              label="Email address"
              name="email"
              initialValue={userData?.adminAdminUser.email}
              rules={[
                {
                  required: true,
                  // type: 'regexp',
                  pattern: new RegExp(
                    /[a-z0-9!#$%&'*+/=?^_'{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_'{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/,
                  ),
                  message: 'The input is not valid E-mail!',
                },
                {required: true, message: 'Email address is required!'},
              ]}>
              <InputCustom placeholder="john@mail.com" width={22} allowClear />
            </FormItemCustom>
            <FormItemCustom
              label="Tax number"
              name="taxNumber"
              initialValue={userData?.adminAdminUser.taxNumber}
              rules={[{required: true, message: 'Tax number is required!'}]}>
              <InputCustom placeholder="0000000000" width={15} allowClear />
            </FormItemCustom>
          </RowInputFields>
        </ContainerDataSection>

        {/* Billing data section */}
        <ContainerDataSection>
          <TextSectionTitle>Billing data</TextSectionTitle>
          <Divider />
          <RowInputFields>
            <FormItemCustom
              label="Account holder name"
              name="accountHolderName"
              initialValue={userData?.adminAdminUser.accountHolderName}
              rules={[
                {
                  required: true,
                  message: 'Account holder name is required!',
                },
              ]}>
              <InputCustom placeholder="John Doe" width={18} allowClear />
            </FormItemCustom>
            <FormItemCustom
              label="Name of the user's bank"
              name="bankName"
              initialValue={userData?.adminAdminUser.bankName}
              rules={[
                {
                  required: true,
                  message: "Name of the user's bank is required!",
                },
              ]}>
              <InputCustom placeholder="OTP bank" width={18} allowClear />
            </FormItemCustom>
            <FormItemCustom
              label="IBAN"
              name="iban"
              initialValue={userData?.adminAdminUser.iban}
              rules={[{required: true, message: 'IBAN is required!'}]}>
              <InputCustom
                placeholder="HU93116000060000000012345676"
                width={18}
                allowClear
              />
            </FormItemCustom>
          </RowInputFields>
          <RowInputFields>
            <FormItemCustom
              label="SWIFT / BIC Code (required for not EU bank account)"
              name="swift"
              initialValue={userData?.adminAdminUser.swift}
              rules={
                [
                  /*{required: true, message: 'swift/bic is required!'}*/
                ]
              }>
              <InputCustom placeholder="OSDDDE81428" width={18} allowClear />
            </FormItemCustom>
          </RowInputFields>
        </ContainerDataSection>

        {/* Action buttons */}
        <RowActionButtons>
          <Space size={15}>
            <Button
              onClick={() =>
                confirm({
                  title: 'Are you sure to go back?',
                  icon: <ExclamationCircleOutlined />,
                  content: 'Unsaved data will be lost!',
                  onOk() {
                    history.goBack();
                  },
                })
              }>
              Back
            </Button>
            <Button type="primary" htmlType="submit">
              Save
            </Button>
          </Space>
        </RowActionButtons>
      </Form>
    </ContainerContent>
  );
};

export default UserDetailsScreen;

import React, {useEffect} from 'react';
import {gql, useMutation} from '@apollo/client';
import {notification} from 'antd';
import {useDispatch, useSelector} from 'react-redux';
import Auth from '../../../logic/redux/auth';
import {adminLogin, adminLoginVariables} from './__generated__/adminLogin';

const useLogin = () => {
  const dispatch = useDispatch();
  const loggedIn = useSelector(Auth.select.selectLoggedIn);
  const redirectPath = useSelector(Auth.select.selectLoginRedirectPath);
  const [
    loginCall,
    {data: loginData, loading: loginLoading, error: loginError},
  ] = useMutation<adminLogin, adminLoginVariables>(gql`
    mutation adminLogin($email: String!, $password: String!) {
      adminLogin(email: $email, password: $password) {
        user {
          name
          role
        }
      }
    }
  `);

  useEffect(() => {
    if (loginError) {
      console.log('loginError.message', loginError.message);
      switch (loginError.message) {
        case 'invalid email or password':
          notification['error']({
            message: 'Invalid email or password!',
          });
          break;
        default:
          notification['error']({
            message: 'Error in login!',
          });
      }
    }
  }, [loginError]);

  React.useEffect(() => {
    if (loginData) {
      dispatch(Auth.actions.setUserLoggedIn(true));
      dispatch(Auth.actions.setAdminUser(loginData.adminLogin.user));
    }
  }, [loginData]);

  return {
    loggedIn,
    loginLoading,
    loginError,
    redirectPath,
    loginCall,
  };
};

export default useLogin;

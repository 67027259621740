import * as React from 'react';
import {
  ContainerTableContent,
  ContainerSearchSection,
  RowSearchSection,
  TextSectionTitle,
  ColumnSearchSection,
  ContainerTable,
  TableContent,
  ContainerPagiantion,
  RowInformationSection,
  IconInfo,
  TextInformation,
} from '../../styled';
import {Button, Modal, Pagination} from 'antd';
import useErrorMessagesScreen from './hooks/use-error-messages-screen';
import {ColumnsType} from 'antd/es/table';
import {FC} from 'react';

interface ErrorMessages {
  id: number;
  createdAt: string;
  message: string;
  os: string;
  phoneType: string;
  email?: string;
  name?: string;
}

const ErrorDetailsComponent: FC<ErrorMessages> = ({
  message,
  os,
  phoneType,
  name,
  email,
}) => {
  return (
    <span>
      Message: {message}, Phone type: {phoneType}, Os: {os}
      {name !== null && name && `, Name: ${name}`}
      {email !== null && email && `, E-mail: ${email}`}
    </span>
  );
};

const ErrorMessagesScreen = (): JSX.Element => {
  const {errorMessagesData, errorMessagesLoading, setPage, page, pageSize} =
    useErrorMessagesScreen();

  const errorMessages = errorMessagesData?.adminListErrorReport.errors || [];
  const pageCount = errorMessagesData?.adminListErrorReport.pageCount;

  const errorMessageTableDataSource: ErrorMessages[] = errorMessages.map(
    (errorMessage) => ({
      id: errorMessage.id,
      createdAt: errorMessage.createdAt,
      message: errorMessage.message,
      os: errorMessage.os,
      phoneType: errorMessage.phoneType,
      email: errorMessage.email ?? undefined,
      name: errorMessage.name ?? undefined,
    }),
  );

  const columns: ColumnsType<ErrorMessages> = [
    {
      title: 'Date',
      dataIndex: 'createdAt',
      key: 'createdAt',
    },
    {
      dataIndex: 'message',
      key: 'message',
      render: (text: string, row: ErrorMessages) => {
        return (
          <Button
            type="primary"
            onClick={() => {
              Modal.info({
                content: <ErrorDetailsComponent {...row} />,
              });
            }}>
            Details
          </Button>
        );
      },
    },
  ];

  return (
    <ContainerTableContent>
      {/* Search bar section */}
      <ContainerSearchSection>
        <RowSearchSection>
          <ColumnSearchSection>
            <TextSectionTitle>Error messages</TextSectionTitle>
          </ColumnSearchSection>
        </RowSearchSection>
      </ContainerSearchSection>

      <ContainerTable>
        {/* Information */}
        <RowInformationSection>
          <IconInfo />
          <TextInformation>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
            ad minim veniam.
          </TextInformation>
        </RowInformationSection>

        {/* Table */}
        <TableContent
          dataSource={errorMessageTableDataSource}
          columns={columns as never}
          pagination={false}
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          rowKey={(record: ErrorMessages) => record.id}
          loading={errorMessagesLoading}
        />

        {/* Pagination */}
        <ContainerPagiantion>
          <Pagination
            onChange={setPage}
            pageSize={pageSize}
            total={
              pageCount !== undefined ? pageCount * pageSize : 1 * pageSize
            }
            current={page}
            showSizeChanger={false}
          />
        </ContainerPagiantion>
      </ContainerTable>
    </ContainerTableContent>
  );
};

export default ErrorMessagesScreen;

import styled from 'styled-components';
import {Divider} from 'antd';

export const TextTitle = styled.h3`
  font-size: 20px;
  font-weight: 500;
  margin: 0 200px 0 10px;
`;

export const TextAmount = styled.h3`
  font-size: 20px;
  font-weight: 400;
  margin: 0 70px 0 0;
`;

export const DividerCustom = styled(Divider)`
  margin: 5px 0 20px 0;
`;

interface IEmpty {
  width: number;
}

export const Empty = styled.div<IEmpty>`
  width: ${(props) => props.width};
`;

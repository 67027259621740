import React, {FC, memo} from 'react';
import {Button} from 'antd';
import {IPreviewButton} from '../../data-types/interfaces';

const PreviewButton: FC<IPreviewButton> = memo(
  ({file, url, buttonText = 'Preview in new tab'}) => {
    const openImage = () => {
      // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
      if (file.base64 || file.id) {
        const image = new Image();
        // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
        image.src = file.base64 ? file.base64 : url;

        const w = window.open('') as Window;
        w.document.write(image.outerHTML);
      }
    };

    return (
      <Button
        type="primary"
        onClick={() => openImage()}
        // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
        disabled={!(file.base64 || file.id)}>
        {buttonText}
      </Button>
    );
  },
);

export default PreviewButton;

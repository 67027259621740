import * as React from 'react';
import SideMenu from '../../components/side-menu/side-menu';
import UpperMenu from '../../components/upper-menu/upper-menu';
import {SubRoutes} from '../../routes/routes';
import {ContainerMain, RowSideMenuAndContent} from './styled';

const MainScreen = (): JSX.Element => {
  return (
    <ContainerMain>
      <UpperMenu />
      <RowSideMenuAndContent>
        <SideMenu />
        <SubRoutes />
      </RowSideMenuAndContent>
    </ContainerMain>
  );
};

export default MainScreen;

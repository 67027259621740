import React, {useState} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import graphqlClient, {
  resetGraphqlClient,
} from '../../../generated/graphql-apollo';
import {
  ApolloProvider,
  ApolloClient,
  NormalizedCacheObject,
} from '@apollo/client';
import Auth from '../../../logic/redux/auth';

function GqlProvider(props: {children: JSX.Element}): JSX.Element {
  const dispatch = useDispatch();

  const [client, setClient] =
    useState<ApolloClient<NormalizedCacheObject>>(graphqlClient);

  const shouldResetClient = useSelector(Auth.select.selectShouldResetClient);

  React.useEffect(() => {
    if (shouldResetClient) {
      setClient(resetGraphqlClient());
      dispatch(Auth.actions.setShouldResetClient(false));
      window.location.reload();
    }
  }, [shouldResetClient]);
  return <ApolloProvider client={client}>{props.children}</ApolloProvider>;
}

export default GqlProvider;

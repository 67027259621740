import {useState, useEffect} from 'react';
import {gql, useLazyQuery, useMutation} from '@apollo/client';

import {adminRequestPayout} from './__generated__/adminRequestPayout';
import {Modal} from 'antd';
import {
  adminListPayments,
  adminListPaymentsVariables,
} from './__generated__/adminListPayments';

const useFinanceScreen = () => {
  const [page, setPage] = useState<number>(1);
  const pageSize = 50;

  const [getPayments, {data: paymentsData, loading: paymentsLoading}] =
    useLazyQuery<adminListPayments, adminListPaymentsVariables>(
      gql`
        query adminListPayments($paginator: Paginator!) {
          adminListPayments(paginator: $paginator) {
            pageCount
            payments {
              date
              commission
            }
            sumRequestedForPayOutCommission
            sumPaidCommission
            sumRemainingCommission
          }
        }
      `,
      {
        variables: {
          paginator: {
            page: page,
            perPage: pageSize,
          },
        },
        fetchPolicy: 'network-only',
      },
    );

  const [
    requestPayout,
    {
      data: requestPayoutData,
      loading: requestPayoutLoading,
      error: requestPayoutError,
    },
  ] = useMutation<adminRequestPayout>(
    gql`
      mutation adminRequestPayout {
        adminRequestPayout
      }
    `,
  );

  /* Query new data on load and page change */
  useEffect(() => {
    getPayments({
      variables: {
        paginator: {
          page: page,
          perPage: pageSize,
        },
      },
    });
  }, [page]);

  const onRequestPayout = () => {
    requestPayout();
  };

  /* Error handling on request */
  useEffect(() => {
    if (requestPayoutError) {
      Modal.error({
        title: 'Something went wrong!',
        content: 'The payment has not been initiated.',
        onOk() {
          () => Modal.destroyAll();
        },
      });
    }

    if (
      requestPayoutData !== undefined &&
      requestPayoutData !== null &&
      requestPayoutData.adminRequestPayout
    ) {
      Modal.success({
        title: 'Success!',
        content: 'The payment has been initiated.',
      });
      getPayments({
        variables: {
          paginator: {
            page: page,
            perPage: pageSize,
          },
        },
      });
    }
  }, [requestPayoutData, requestPayoutError]);

  /* TODO remove log */
  useEffect(() => {
    console.log('paymentsData', paymentsData);
  }, [paymentsData]);

  return {
    paymentsData,
    paymentsLoading,
    setPage,
    page,
    pageSize,
    requestPayoutLoading,
    onRequestPayout,
  };
};

export default useFinanceScreen;

import {useState, useEffect} from 'react';
import {useHistory} from 'react-router-dom';
import {Modal, message, Form} from 'antd';
import {gql, useLazyQuery, useMutation} from '@apollo/client';
import {adminPlace, adminPlaceVariables} from './__generated__/adminPlace';
import {
  adminSetPlace,
  adminSetPlaceVariables,
} from './__generated__/adminSetPlace';
import {getBase64} from '../../../helpers/file.util';
import {IImageFile} from '../../../data-types/interfaces';

const usePlaceDetailsScreen = (placeIdCurrent: number | undefined) => {
  const history = useHistory();
  const [form] = Form.useForm();
  const [lat, setLat] = useState<number>(47.4979);
  const [lng, setLng] = useState<number>(19.0402);
  const [clearSelect, setClearSelect] = useState<boolean>(false);
  const [isPlaceActive, setIsPlaceActive] = useState<boolean>(false);
  const [isPlaceApproved, setIsPlaceApproved] = useState<boolean>(false);
  const [imageArray, setImageArray] = useState<IImageFile[]>([]);
  const [imageValid, setImageValid] = useState<boolean | undefined>();
  const [isCommission, setIsCommission] = useState<boolean>(false);
  const [commission, setCommission] = useState<number>(0);

  const [
    placeCreate,
    {
      data: placeCreateData,
      loading: placeCreateLoading,
      error: placeCreateError,
    },
  ] = useMutation<adminSetPlace, adminSetPlaceVariables>(
    gql`
      mutation adminSetPlace($place: InputAdminPlace!) {
        adminSetPlace(place: $place)
      }
    `,
  );

  // TODO: commission, commissionPercent
  const [getPlaceData, {data: placeData, loading: placeDataLoading}] =
    useLazyQuery<adminPlace, adminPlaceVariables>(
      gql`
        query adminPlace($placeId: Int!) {
          adminPlace(placeId: $placeId) {
            id
            name
            description
            latLng
            country
            category {
              id
              name
            }
            images {
              name
              id
              base64
            }
            active
            approved
            createdAt
            createdBy {
              name
            }
            commission
            commissionPercent
          }
        }
      `,
      {
        variables: {
          placeId: placeIdCurrent !== undefined ? placeIdCurrent : 0,
        },
        fetchPolicy: 'network-only',
      },
    );

  /* Query place data */
  useEffect(() => {
    if (placeIdCurrent !== undefined && placeIdCurrent !== 0) {
      getPlaceData({
        variables: {
          placeId: placeIdCurrent,
        },
      });
    }
  }, []);

  useEffect(() => {
    if (placeData) {
      // update lat long from gql data
      setLat(placeData.adminPlace.latLng.lat);
      setLng(placeData.adminPlace.latLng.lng);
      setIsCommission(
        placeData.adminPlace.commission !== null
          ? placeData.adminPlace.commission
          : false,
      );

      setCommission(placeData.adminPlace.commissionPercent);

      // fill image array from gql data
      const newImageArray: IImageFile[] = [];
      if (
        // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
        placeData.adminPlace.images &&
        placeData.adminPlace.images.length > 0
      ) {
        for (const newImageData of placeData.adminPlace.images) {
          newImageArray.push({
            name: newImageData.name,
            id: newImageData.id,
            base64: newImageData.base64,
          });
        }
      }

      setImageArray(newImageArray);

      form.resetFields();
      form.setFieldsValue({
        images: newImageArray,
      });
      setImageValid(true);
    }
  }, [placeData]);

  /* Update isPlaceActive state for activate button */
  useEffect(() => {
    if (placeData && placeData.adminPlace.active) {
      setIsPlaceActive(placeData.adminPlace.active);
    }
  }, [placeData?.adminPlace.active]);

  /* Update isPlaceApproved state for approved button */
  useEffect(() => {
    if (placeData && placeData.adminPlace.approved) {
      setIsPlaceApproved(placeData.adminPlace.approved);
    }
  }, [placeData?.adminPlace.approved]);

  function beforeUpload(file: File, index: number) {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';

    getBase64(file, (imageUrl: string | ArrayBuffer | null) => {
      if (!isJpgOrPng) {
        message.error('You can only upload JPG/PNG file!');
      } else {
        const newImageArray: IImageFile[] | undefined = Object.assign(
          [],
          imageArray,
        );
        newImageArray.splice(index, 1, {
          name: '',
          id: '',
          base64: imageUrl as string,
        });

        form.setFieldsValue({
          images: newImageArray,
        });
        setImageArray(newImageArray);
        setImageValid(true);
      }
    });
    return false;
  }

  const validateOnSubmit = () => {
    imageValid === undefined && setImageValid(false);
  };

  const onFinishPlace = (values: {
    name: string;
    description: string;
    category: number;
    countryName: string;
  }) => {
    if (imageValid === true) {
      // TODO: commission, commissionPercent
      placeCreate({
        variables: {
          place: {
            id: placeIdCurrent !== undefined ? placeIdCurrent : 0,
            name: values.name,
            description: values.description,
            latLng: {lat: lat, lng: lng},
            categoryID: values.category,
            images: imageArray,
            country: values.countryName,
            commission: isCommission,
            commissionPercent: isCommission ? commission : 0,
          },
        },
      });
    }
  };

  /* Error handling on save */
  useEffect(() => {
    if (placeCreateError) {
      Modal.error({
        title: 'Something went wrong!',
        content:
          'Create/save place data was failed, please try again or contact us.',
        onOk() {
          () => Modal.destroyAll();
        },
      });
    }
    if (placeCreateData !== undefined && placeCreateData !== null) {
      if (placeCreateData.adminSetPlace) {
        history.goBack();
      }
    }
  }, [placeCreateData, placeCreateError]);

  return {
    placeCreate,
    placeCreateLoading,
    placeData,
    placeDataLoading,
    beforeUpload,
    onFinishPlace,
    lat,
    setLat,
    lng,
    setLng,
    clearSelect,
    setClearSelect,
    form,
    imageArray,
    imageValid,
    validateOnSubmit,
    isPlaceActive,
    setIsPlaceActive,
    isPlaceApproved,
    setIsPlaceApproved,
    isCommission,
    setIsCommission,
    commission,
    setCommission,
  };
};

export default usePlaceDetailsScreen;

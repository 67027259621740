import {useState, useEffect} from 'react';
import {gql, useLazyQuery} from '@apollo/client';
import {
  adminListNotifications,
  adminListNotificationsVariables,
} from './__generated__/adminListNotifications';

const useNotificationScreen = () => {
  const [page, setPage] = useState<number>(1);
  const pageSize = 50;

  const [
    getNotifications,
    {data: notificationData, loading: notificationLoading},
  ] = useLazyQuery<adminListNotifications, adminListNotificationsVariables>(
    gql`
      query adminListNotifications($paginator: Paginator!) {
        adminListNotifications(paginator: $paginator) {
          pageCount
          notifications {
            id
            title
            text
            createdAt
          }
        }
      }
    `,
    {
      variables: {
        paginator: {
          page: page,
          perPage: pageSize,
        },
      },
      fetchPolicy: 'network-only',
    },
  );

  /* Query new data on load and page change */
  useEffect(() => {
    getNotifications({
      variables: {
        paginator: {
          page: page,
          perPage: pageSize,
        },
      },
    });
  }, [page]);

  /* TODO remove log */
  useEffect(() => {
    console.log('notificationData', notificationData);
  }, [notificationData]);

  return {
    notificationData,
    notificationLoading,
    setPage,
    page,
    pageSize,
  };
};

export default useNotificationScreen;
